import { type FC } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { SbSharedWordpressArticlesWrapper } from '../SbSharedWordpressArticles/SbSharedWordpressArticlesWrapper';
import { SbMobileInteriorsModule } from './SbMobileInteriorsModule';
import { showInteriorsModule } from '../SbSharedInteriorsModule/interiorsModuleHelpers';

import { type SbMobileInteriorsWordpressArticlesWrapper_itemSearch$data } from './__generated__/SbMobileInteriorsWordpressArticlesWrapper_itemSearch.graphql';
import { type SbMobileInteriorsWordpressArticlesWrapper_viewer$data } from './__generated__/SbMobileInteriorsWordpressArticlesWrapper_viewer.graphql';
import { type SbMobileInteriorsWordpressArticlesWrapper_user$data } from './__generated__/SbMobileInteriorsWordpressArticlesWrapper_user.graphql';

type Props = {
    itemSearch: SbMobileInteriorsWordpressArticlesWrapper_itemSearch$data;
    viewer: SbMobileInteriorsWordpressArticlesWrapper_viewer$data;
    user: SbMobileInteriorsWordpressArticlesWrapper_user$data;
    isClient: boolean;
};

const SbMobileInteriorsWordpressArticlesWrapperComponent: FC<Props> = props => {
    const { itemSearch, viewer, user, isClient } = props;
    const editorial = itemSearch.editorial;

    if (showInteriorsModule(itemSearch.interiorPhotos?.photos)) {
        return (
            <SbMobileInteriorsModule
                editorial={editorial}
                itemSearch={itemSearch}
                user={user}
                viewer={viewer}
                isClient={isClient}
            />
        );
    } else {
        return <SbSharedWordpressArticlesWrapper editorial={editorial} viewer={viewer} />;
    }
};

export const SbMobileInteriorsWordpressArticlesWrapper = createFragmentContainer(
    SbMobileInteriorsWordpressArticlesWrapperComponent,
    {
        viewer: graphql`
            fragment SbMobileInteriorsWordpressArticlesWrapper_viewer on Viewer {
                ...SbSharedWordpressArticlesWrapper_viewer
                ...SbMobileInteriorsModule_viewer
            }
        `,
        itemSearch: graphql`
            fragment SbMobileInteriorsWordpressArticlesWrapper_itemSearch on ItemSearchQueryConnection {
                ...SbMobileInteriorsModule_itemSearch
                editorial: editorialArticles(first: 8) {
                    ...SbMobileInteriorsModule_editorial
                    ...SbSharedWordpressArticlesWrapper_editorial
                }
                interiorPhotos {
                    photos {
                        serviceId
                    }
                }
            }
        `,
        user: graphql`
            fragment SbMobileInteriorsWordpressArticlesWrapper_user on User {
                ...SbMobileInteriorsModule_user
            }
        `,
    }
);
