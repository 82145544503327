'use strict';

import { graphql } from 'react-relay/legacy';

export const sbMobileRoot = graphql`
    query sbMobileRootQuery(
        $userId: String = ""
        $personalizationId: String = ""
        $guestId: String = ""
        $rerankUserId: String = ""
        $rerankGuestId: String = ""
        $fetchUser: Boolean!
        $uriRef: String = ""
        $originalUrl: String = ""
        $first: Int!
        $page: Int!
        $showSeller: Boolean!
        $followSearchPages: [String]
        $followSearchTypes: [types] = [SEARCH, USER_PREFERENCE]
        $isTrade: Boolean!
        $localeOrigin: String = ""
        $contentModuleId: String = ""
        $regions: String = ""
        $userZipCode: String = ""
        $userCountryCode: String = ""
        $fetchRegionalInfo: Boolean = false
        $fetchSb: Boolean!
        $fetchTileVideo: Boolean = false
        $disableForceFacet: Boolean = false
        $isClient: Boolean!
        $disableNonParameterizedUrlRedirects: Boolean = false # Only used for static SB
        $priceBookName: String
        $regionsList: [String]
        $includeSponsoredItems: Boolean = false
        $pageDisplayEnum: PageDisplayEnum = searchAndBrowse
    ) {
        viewer {
            ...SbMobileLayout_viewer
            ...detectPageExistsAndRedirect_viewer
        }
    }
`;
