import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { connect } from 'react-redux';
import get from 'lodash/get';

// components
import { BackToTop } from '../../components/global/BackToTop/BackToTop';
import { SharedFollowSearchBannerLazy } from '../../shared/SharedFollowSearchBanner/SharedFollowSearchBannerLazy';
import { SbSharedPageBreadcrumbs } from '../SbSharedPageBreadcrumbs/SbSharedPageBreadcrumbs';
import { pageTypeConstants as pageTypes } from '../../constants/pageTypeConstants';
import { SbMobileNullSearchResults } from '../SbMobileNullSearchResults/SbMobileNullSearchResults';
import { SbMobileCategoryTiles } from '../SbMobileCategoryTiles/SbMobileCategoryTiles';
import { SbMobileDesignTiles } from '../SbMobileDesignTiles/SbMobileDesignTiles';
import { SbSharedTopTileController } from '../SbSharedTopTileController/SbSharedTopTileController';
import { SbSharedBillboardAd } from '../SbSharedBillboardAd/SbSharedBillboardAd';
import { FullPageWidth } from '../../components/global/FullPageWidth/FullPageWidth';
import { SbSharedRelatedItems } from '../SbSharedRelatedItems/SbSharedRelatedItems';
import { SbSharedRecentlySoldItems } from '../SbSharedRecentlySoldItems/SbSharedRecentlySoldItems';
import { SbMobileHeader } from '../SbMobileHeader/SbMobileHeader';
import { SbSharedPersonalizedFilterToast } from '../SbSharedPersonalizedFilterToast/SbSharedPersonalizedFilterToast';
import { SbMobileMainContent } from '../SbMobileMainContent/SbMobileMainContent';
import { SbSharedControlledHeadingWrapper } from '../SbSharedControlledHeadingWrapper/SbSharedControlledHeadingWrapper';
import { SharedMobileLinksBlock } from '../../shared/SharedMobileLinksBlock/SharedMobileLinksBlock';
import { SharedRelatedPages } from '../../shared/SharedRelatedPages/SharedRelatedPages';
import { SbMobileInteriorsWordpressArticlesWrapper } from '../SbMobileInteriorsModule/SbMobileInteriorsWordpressArticlesWrapper';
import { SbSharedRelatedCreators } from '../SbSharedRelatedCreators/SbSharedRelatedCreators';
import { SbSharedRelatedSearches } from '../SbSharedRelatedSearches/SbSharedRelatedSearches';
import { SharedFAQ } from '../../shared/SharedFAQ/SharedFAQ';
import { SharedBottomModule } from '../../shared/SharedBottomModule/SharedBottomModule';
import { SharedRecentSales } from '../../shared/SharedRecentSales/SharedRecentSales';
import { SbSharedRecommendedItems } from '../SbSharedRecommendedItems/SbSharedRecommendedItems';
import SharedYMAL from '../../shared/SharedYMAL/SharedYMAL';
import { getFilterValueByUrlLabel } from '../SbSharedRefineMenu/sbSharedRefineMenuHelpers';
import { SbMobileFollowPage } from '../SbMobileFollowPage/SbMobileFollowPage';
import { SharedSaveSearchContextProvider } from '../../shared/SharedSaveSearchContext/SharedSaveSearchContext';
import { useCurrency } from 'dibs-buyer-layout/exports/useCurrency';

import {
    trackNullResultsFollowSearch,
    trackL1ModuleClick,
    trackCreatorModuleClick,
    trackRoomModuleClick,
} from '../../utils/tracking/searchBrowse/nullResultsTracking';

// constants
import {
    MIN_THIN_PAGE_COUNT,
    REVEAL_RELATED_CREATORS_COUNT_MOBILE,
} from '../../constants/sbConstants';
import { SEARCH_TYPES, FILTER_SEARCH_TYPE } from '../../constants/sbConstants';

import styles from './SbMobileComponents.scss';

const { SEARCH } = pageTypes;
const { FUZZY, SEMANTIC } = SEARCH_TYPES;

/**
 * Container for search / browse body components
 */
export const SbMobileComponentsComponent = props => {
    const { viewer, relay, itemSearch, user, isClient, shippingFilters } = props;

    const {
        appliedFilters = [],
        totalResults,
        pageType,
        breadcrumbs,
        internalBuyLinks = [],
        bottomModule,
        soldItems,
    } = itemSearch;

    const { isRelatedCreatorsModuleEnabled } = viewer;

    const { currency } = useCurrency();

    const getSearchTypeFilterValue = searchType =>
        getFilterValueByUrlLabel(appliedFilters, FILTER_SEARCH_TYPE, searchType);

    const isSearch = pageType === SEARCH;
    const isNullResultPage = totalResults === 0;
    const isThinPage = totalResults < MIN_THIN_PAGE_COUNT;
    const showBreadcrumbs = breadcrumbs.length > 1;
    const searchTerm = get(
        appliedFilters.filter(({ name }) => name === SEARCH),
        '[0].values[0].urlLabel'
    );
    const showBillboardAdUnit = !isThinPage;
    const isFuzzyResults = !!getSearchTypeFilterValue(FUZZY);
    const isSemanticSearchType = !!getSearchTypeFilterValue(SEMANTIC);
    const showFollowButton = !isSemanticSearchType && searchTerm;

    return (
        <SharedSaveSearchContextProvider
            itemSearch={itemSearch}
            user={user}
            searchTerm={searchTerm}
            uriRef={itemSearch.displayUriRef}
        >
            {/* if has 0 results or it's fuzzy results with sold items (SEARCH-1598) */}
            {isNullResultPage || (isFuzzyResults && soldItems?.totalResults) ? (
                <>
                    <SbMobileHeader
                        viewer={viewer}
                        user={user}
                        itemSearch={itemSearch}
                        isFuzzyResults={isFuzzyResults}
                    />
                    {soldItems?.totalResults ? (
                        <div className={styles.container}>
                            <SharedRecentSales
                                viewer={viewer}
                                user={user}
                                itemSearch={itemSearch}
                                isMobile
                                isClient={isClient}
                            />
                            <SharedYMAL
                                user={user}
                                itemSearch={itemSearch}
                                isMobile
                                isClient={isClient}
                                relay={relay}
                            />
                            <SbSharedRecommendedItems viewer={viewer} />
                        </div>
                    ) : (
                        <>
                            {isClient && (
                                <SharedFollowSearchBannerLazy
                                    itemSearch={itemSearch}
                                    user={user}
                                    onComplete={trackNullResultsFollowSearch}
                                />
                            )}
                            <SbMobileNullSearchResults
                                itemSearch={itemSearch}
                                onL1ModuleClick={trackL1ModuleClick}
                                onCreatorModuleClick={trackCreatorModuleClick}
                                onRoomModuleClick={trackRoomModuleClick}
                            />
                        </>
                    )}
                </>
            ) : (
                <>
                    {/* Breadcrumbs */}
                    {showBreadcrumbs && (
                        <SbSharedPageBreadcrumbs itemSearch={itemSearch} user={user} />
                    )}

                    <SbSharedControlledHeadingWrapper itemSearch={itemSearch}>
                        {Heading => (
                            <>
                                {/* Follow Page Link */}
                                {showFollowButton && (
                                    <SbMobileFollowPage itemSearch={itemSearch} viewer={viewer} />
                                )}
                                {/* Page SbMobileHeader */}
                                <SbMobileHeader
                                    viewer={viewer}
                                    itemSearch={itemSearch}
                                    user={user}
                                    Heading={Heading}
                                    showTitle={!searchTerm}
                                />

                                {/* Related Searches */}
                                <SbSharedRelatedSearches itemSearch={itemSearch} />

                                {/* Broad Search Category Tiles */}
                                <SbSharedTopTileController
                                    itemSearch={itemSearch}
                                    viewer={viewer}
                                    categoryTiles={
                                        <FullPageWidth>
                                            <SbMobileCategoryTiles itemSearch={itemSearch} />
                                        </FullPageWidth>
                                    }
                                    designTiles={
                                        <FullPageWidth>
                                            <SbMobileDesignTiles itemSearch={itemSearch} />
                                        </FullPageWidth>
                                    }
                                    tradeOnApprovalBanner={null}
                                    priceTiles={null}
                                />

                                <SbMobileMainContent
                                    currency={currency}
                                    itemSearch={itemSearch}
                                    viewer={viewer}
                                    user={user}
                                    isClient={isClient}
                                />

                                {/* Related Items */}
                                {isThinPage && (
                                    <>
                                        <SbSharedRelatedItems
                                            viewer={viewer}
                                            itemSearch={itemSearch}
                                        />
                                        <SbSharedRecentlySoldItems
                                            viewer={viewer}
                                            itemSearch={itemSearch}
                                        />
                                    </>
                                )}

                                {/* Related Searches With Title */}
                                <SbSharedRelatedSearches itemSearch={itemSearch} showTitle />

                                {/* Follow Search */}
                                {isClient && (
                                    <FullPageWidth hasBackground>
                                        <SharedFollowSearchBannerLazy
                                            itemSearch={itemSearch}
                                            user={user}
                                        />
                                    </FullPageWidth>
                                )}

                                {/* Bottom Module */}
                                <SharedBottomModule
                                    className={styles.bottomModule}
                                    bottomModule={bottomModule}
                                />

                                {/* Ad */}
                                {showBillboardAdUnit && (
                                    <SbSharedBillboardAd itemSearch={itemSearch} />
                                )}

                                {/* Related Creators for creator pages */}
                                {isRelatedCreatorsModuleEnabled && (
                                    <SbSharedRelatedCreators
                                        itemSearch={itemSearch}
                                        revealCreatorCount={REVEAL_RELATED_CREATORS_COUNT_MOBILE}
                                    />
                                )}

                                {/* Questions & Answers */}
                                <SharedFAQ itemSearch={itemSearch} />

                                {/* Interiors and/or Editorial */}
                                <div className={styles.container}>
                                    <SbMobileInteriorsWordpressArticlesWrapper
                                        itemSearch={itemSearch}
                                        viewer={viewer}
                                        user={user}
                                        isClient={isClient}
                                    />
                                </div>

                                {isSearch && isThinPage && (
                                    <div className={styles.container}>
                                        {/* Recommended Items */}
                                        <SbSharedRecommendedItems viewer={viewer} />
                                    </div>
                                )}

                                {/*  Related pages */}
                                <SharedRelatedPages
                                    viewer={viewer}
                                    itemSearch={itemSearch}
                                    LinksBlockComponent={SharedMobileLinksBlock}
                                    borderTop
                                />

                                {/*  More Ways to Browse */}
                                <SharedMobileLinksBlock
                                    viewer={viewer}
                                    item={null}
                                    itemSearch={itemSearch}
                                    links={internalBuyLinks}
                                    borderTop
                                />
                            </>
                        )}
                    </SbSharedControlledHeadingWrapper>

                    {/* Back to Top Button */}
                    <BackToTop />

                    {/* Personalized Filter Toast */}
                    <SbSharedPersonalizedFilterToast shippingFilters={shippingFilters} />
                </>
            )}
        </SharedSaveSearchContextProvider>
    );
};

SbMobileComponentsComponent.propTypes = {
    viewer: PropTypes.object.isRequired,
    relay: PropTypes.object.isRequired,
    itemSearch: PropTypes.object.isRequired,
    user: PropTypes.object,
    isClient: PropTypes.bool,
    // redux
    shippingFilters: PropTypes.array,
};

const mapStateToProps = state => {
    const { filters } = state;

    const { refetchInFlight } = filters;

    return {
        refetchInFlight: refetchInFlight,
    };
};

export const SbMobileComponents = createFragmentContainer(
    connect(mapStateToProps)(SbMobileComponentsComponent),
    {
        viewer: graphql`
            fragment SbMobileComponents_viewer on Viewer {
                isRelatedCreatorsModuleEnabled: featureFlag(feature: "relatedCreatorsModule")
                ...SharedRelatedPages_viewer
                ...SharedMobileLinksBlock_viewer
                ...SbMobileMainContent_viewer
                ...SbSharedTopTileController_viewer
                ...SbSharedRelatedItems_viewer
                ...SbSharedRecentlySoldItems_viewer
                ...SbMobileInteriorsWordpressArticlesWrapper_viewer
                ...SbSharedRecommendedItems_viewer
                ...SharedRecentSales_viewer
            }
        `,
        itemSearch: graphql`
            fragment SbMobileComponents_itemSearch on ItemSearchQueryConnection {
                ...SbMobileHeader_itemSearch
                ...SbSharedTopTileController_itemSearch
                ...SbSharedRelatedSearches_itemSearch
                ...SbMobileCategoryTiles_itemSearch
                ...SbMobileDesignTiles_itemSearch
                ...SbSharedPageBreadcrumbs_itemSearch
                ...SbSharedRelatedItems_itemSearch
                ...SharedFollowSearchBanner_itemSearch
                ...SbSharedBillboardAd_itemSearch
                ...SbMobileMainContent_itemSearch
                ...SbSharedControlledHeadingWrapper_itemSearch
                ...SharedRelatedPages_itemSearch
                ...SbMobileInteriorsWordpressArticlesWrapper_itemSearch
                ...SharedWordpressArticles_itemSearch
                ...SbSharedRelatedCreators_itemSearch
                ...SbSharedRecentlySoldItems_itemSearch
                ...SharedSaveSearchContext_itemSearch
                breadcrumbs {
                    __typename
                }
                displayUriRef
                pageType
                totalResults
                appliedFilters {
                    name
                    values {
                        displayName
                        urlLabel
                        count
                        hexCode
                    }
                }
                ...SharedMobileLinksBlock_itemSearch
                internalBuyLinks {
                    ...SharedMobileLinksBlock_links
                }
                ...SharedFAQ_itemSearch
                ...SharedRecentSales_itemSearch
                ...SharedYMAL_itemSearch @arguments(priceBookName: $priceBookName)
                bottomModule {
                    ...SharedBottomModule_bottomModule
                }
                soldItems: soldResults(first: 28) {
                    totalResults
                }
                ...SbMobileFollowPage_itemSearch
            }
        `,
        user: graphql`
            fragment SbMobileComponents_user on User {
                ...SharedFollowSearchBanner_user
                ...SbMobileMainContent_user
                ...SbMobileInteriorsWordpressArticlesWrapper_user
                ...SharedRecentSales_user
                ...SharedYMAL_user
                ...SbMobileHeader_user
                ...SbSharedPageBreadcrumbs_user
                ...SharedSaveSearchContext_user
            }
        `,
        shippingFilters: graphql`
            fragment SbMobileComponents_shippingFilters on ShippingFilterType @relay(plural: true) {
                ...SbSharedPersonalizedFilterToast_shippingFilters
            }
        `,
    }
);
