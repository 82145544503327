/**
 * @generated SignedSource<<891deb61ea46bd9658f526b2e8004ea6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbMobileComponents_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbMobileHeader_user" | "SbMobileInteriorsWordpressArticlesWrapper_user" | "SbMobileMainContent_user" | "SbSharedPageBreadcrumbs_user" | "SharedFollowSearchBanner_user" | "SharedRecentSales_user" | "SharedSaveSearchContext_user" | "SharedYMAL_user">;
  readonly " $fragmentType": "SbMobileComponents_user";
};
export type SbMobileComponents_user$key = {
  readonly " $data"?: SbMobileComponents_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbMobileComponents_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbMobileComponents_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedFollowSearchBanner_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbMobileMainContent_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbMobileInteriorsWordpressArticlesWrapper_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedRecentSales_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedYMAL_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbMobileHeader_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedPageBreadcrumbs_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedSaveSearchContext_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "d46f3c8571e38b6bdad2ca77efd59e62";

export default node;
