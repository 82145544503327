/**
 * @generated SignedSource<<4b0b0f4d3b665777fa491993abe7314e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbMobileComponents_shippingFilters$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedPersonalizedFilterToast_shippingFilters">;
  readonly " $fragmentType": "SbMobileComponents_shippingFilters";
}>;
export type SbMobileComponents_shippingFilters$key = ReadonlyArray<{
  readonly " $data"?: SbMobileComponents_shippingFilters$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbMobileComponents_shippingFilters">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SbMobileComponents_shippingFilters",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedPersonalizedFilterToast_shippingFilters"
    }
  ],
  "type": "ShippingFilterType",
  "abstractKey": null
};

(node as any).hash = "56839dde6e123896e205aed30c9aac01";

export default node;
