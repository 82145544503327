/**
 * @generated SignedSource<<967459168856617531ab5badca930b5a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbMobileInteriorsPhotoCarousel_photos$data = ReadonlyArray<{
  readonly id: string;
  readonly name: string | null;
  readonly path: string | null;
  readonly serviceId: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedInteriorsHeartWrapper_photo" | "usePhotosLikeDataRefetch_photos">;
  readonly " $fragmentType": "SbMobileInteriorsPhotoCarousel_photos";
}>;
export type SbMobileInteriorsPhotoCarousel_photos$key = ReadonlyArray<{
  readonly " $data"?: SbMobileInteriorsPhotoCarousel_photos$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbMobileInteriorsPhotoCarousel_photos">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SbMobileInteriorsPhotoCarousel_photos",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "path",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "usePhotosLikeDataRefetch_photos"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedInteriorsHeartWrapper_photo"
    }
  ],
  "type": "PhotosSearchBrowsePhoto",
  "abstractKey": null
};

(node as any).hash = "f44ce16b0b6b350d9d4f8b6d07ccde30";

export default node;
