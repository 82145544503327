/**
 * @generated SignedSource<<df313d2e01dfe89dbb0cae47423f0e3e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbMobileComponents_itemSearch$data = {
  readonly appliedFilters: ReadonlyArray<{
    readonly name: string | null;
    readonly values: ReadonlyArray<{
      readonly count: number | null;
      readonly displayName: string | null;
      readonly hexCode: string | null;
      readonly urlLabel: string | null;
    } | null> | null;
  } | null> | null;
  readonly bottomModule: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"SharedBottomModule_bottomModule">;
  } | null> | null;
  readonly breadcrumbs: ReadonlyArray<{
    readonly __typename: "LinkData";
  }> | null;
  readonly displayUriRef: string | null;
  readonly internalBuyLinks: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"SharedMobileLinksBlock_links">;
  } | null> | null;
  readonly pageType: string | null;
  readonly soldItems: {
    readonly totalResults: number | null;
  } | null;
  readonly totalResults: number | null;
  readonly " $fragmentSpreads": FragmentRefs<"SbMobileCategoryTiles_itemSearch" | "SbMobileDesignTiles_itemSearch" | "SbMobileFollowPage_itemSearch" | "SbMobileHeader_itemSearch" | "SbMobileInteriorsWordpressArticlesWrapper_itemSearch" | "SbMobileMainContent_itemSearch" | "SbSharedBillboardAd_itemSearch" | "SbSharedControlledHeadingWrapper_itemSearch" | "SbSharedPageBreadcrumbs_itemSearch" | "SbSharedRecentlySoldItems_itemSearch" | "SbSharedRelatedCreators_itemSearch" | "SbSharedRelatedItems_itemSearch" | "SbSharedRelatedSearches_itemSearch" | "SbSharedTopTileController_itemSearch" | "SharedFAQ_itemSearch" | "SharedFollowSearchBanner_itemSearch" | "SharedMobileLinksBlock_itemSearch" | "SharedRecentSales_itemSearch" | "SharedRelatedPages_itemSearch" | "SharedSaveSearchContext_itemSearch" | "SharedWordpressArticles_itemSearch" | "SharedYMAL_itemSearch">;
  readonly " $fragmentType": "SbMobileComponents_itemSearch";
};
export type SbMobileComponents_itemSearch$key = {
  readonly " $data"?: SbMobileComponents_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbMobileComponents_itemSearch">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalResults",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "priceBookName"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbMobileComponents_itemSearch",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbMobileHeader_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedTopTileController_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRelatedSearches_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbMobileCategoryTiles_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbMobileDesignTiles_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedPageBreadcrumbs_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRelatedItems_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedFollowSearchBanner_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedBillboardAd_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbMobileMainContent_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedControlledHeadingWrapper_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedRelatedPages_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbMobileInteriorsWordpressArticlesWrapper_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedWordpressArticles_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRelatedCreators_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRecentlySoldItems_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedSaveSearchContext_itemSearch"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LinkData",
      "kind": "LinkedField",
      "name": "breadcrumbs",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayUriRef",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pageType",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "SearchBrowseParsedParam",
      "kind": "LinkedField",
      "name": "appliedFilters",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SearchBrowseParsedParamValue",
          "kind": "LinkedField",
          "name": "values",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "urlLabel",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "count",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hexCode",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedMobileLinksBlock_itemSearch"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "InternalBuyLinkType",
      "kind": "LinkedField",
      "name": "internalBuyLinks",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SharedMobileLinksBlock_links"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedFAQ_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedRecentSales_itemSearch"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "priceBookName",
          "variableName": "priceBookName"
        }
      ],
      "kind": "FragmentSpread",
      "name": "SharedYMAL_itemSearch"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BottomModule",
      "kind": "LinkedField",
      "name": "bottomModule",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SharedBottomModule_bottomModule"
        }
      ],
      "storageKey": null
    },
    {
      "alias": "soldItems",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 28
        }
      ],
      "concreteType": "soldResultsConnection",
      "kind": "LinkedField",
      "name": "soldResults",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": "soldResults(first:28)"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbMobileFollowPage_itemSearch"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};
})();

(node as any).hash = "276057b4a393f45d2dfeb94f227da499";

export default node;
