import { type FC } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { Swiper } from 'dibs-pinch-zoom-swipe';
import LazyImage from 'dibs-react-lazy-image';
import { usePhotosLikeDataRefetch } from 'dibs-interiors-photos/exports/usePhotosLikeDataRefetch';

import { getSrcsetString, getSrcsetUrl } from 'dibs-image-utils/exports/srcSet';
import SbSharedInteriorsHeartWrapper from '../SbSharedInteriorsModule/SbSharedInteriorsHeartWrapper/SbSharedInteriorsHeartWrapper';
import { SbSharedInteriorsBrowseMore } from '../SbSharedInteriorsModule/SbSharedInteriorsBrowseMore';

import { type SbMobileInteriorsPhotoCarousel_itemSearch$data } from './__generated__/SbMobileInteriorsPhotoCarousel_itemSearch.graphql';
import { type SbMobileInteriorsPhotoCarousel_photos$data } from './__generated__/SbMobileInteriorsPhotoCarousel_photos.graphql';
import { type SbMobileInteriorsPhotoCarousel_user$data } from './__generated__/SbMobileInteriorsPhotoCarousel_user.graphql';

import styles from './SbMobileInteriorsPhotoCarousel.scss';

const LAZY_IMAGE_VERTICAL_OFFSET = 200;
const DEFAULT_IMAGE_WIDTH = 600;
const IMAGE_SIZES = '66vw';

type Props = {
    itemSearch: SbMobileInteriorsPhotoCarousel_itemSearch$data;
    photos: SbMobileInteriorsPhotoCarousel_photos$data;
    onPageChange: (nextPage: number) => void;
    onPhotoClick: () => void;
    isClient: boolean;
    onPhotoLikeAdded: () => void;
    user: SbMobileInteriorsPhotoCarousel_user$data;
    isModuleVisible: boolean;
};

const SbMobileInteriorsPhotoCarouselComponent: FC<Props> = ({
    itemSearch,
    photos,
    onPageChange,
    onPhotoClick,
    isClient,
    onPhotoLikeAdded,
    user,
    isModuleVisible,
}) => {
    const { displayUriRef } = itemSearch;
    const { photoLikeDataMap, isRefetching } = usePhotosLikeDataRefetch({
        photosRef: photos,
        shouldFetch: isModuleVisible,
        userId: user?.serviceId || '',
    });
    const hasMorePhotos = (itemSearch?.interiorPhotos?.totalResults || 0) > photos.length;

    return (
        <Swiper
            classNames={{
                wrapper: styles.swiperWrapper,
                item: styles.swiperItem,
            }}
            itemsPerPage={1.5}
            onPageChange={onPageChange}
            key={displayUriRef}
        >
            {photos.map((photo, index) => {
                const photoPath = photo.path || '';
                const { photoLikeId } = photoLikeDataMap.get(photo?.serviceId || '') || {};
                return (
                    <div
                        key={photoPath || index}
                        data-tn={`interior-photo-${index}`}
                        onClick={onPhotoClick}
                        onKeyPress={onPhotoClick}
                        className={styles.link}
                        tabIndex={0}
                        role="link"
                    >
                        <LazyImage
                            className={styles.lazyLoad}
                            offsetVertical={LAZY_IMAGE_VERTICAL_OFFSET}
                        >
                            <img
                                className={styles.img}
                                srcSet={getSrcsetString(photoPath)}
                                src={getSrcsetUrl(photoPath, DEFAULT_IMAGE_WIDTH)}
                                sizes={IMAGE_SIZES}
                                alt={photo.name || ''}
                            />
                        </LazyImage>
                        {isClient && !isRefetching && (
                            <SbSharedInteriorsHeartWrapper
                                onPhotoLikeAdded={onPhotoLikeAdded}
                                photoLikeId={photoLikeId}
                                photo={photo}
                            />
                        )}
                    </div>
                );
            })}
            {hasMorePhotos && (
                <SbSharedInteriorsBrowseMore interiorPhotos={itemSearch?.interiorPhotos} />
            )}
        </Swiper>
    );
};

export const SbMobileInteriorsPhotoCarousel = createFragmentContainer(
    SbMobileInteriorsPhotoCarouselComponent,
    {
        itemSearch: graphql`
            fragment SbMobileInteriorsPhotoCarousel_itemSearch on ItemSearchQueryConnection {
                displayUriRef
                interiorPhotos {
                    ...SbSharedInteriorsBrowseMore_interiorPhotos
                    totalResults
                }
            }
        `,
        photos: graphql`
            fragment SbMobileInteriorsPhotoCarousel_photos on PhotosSearchBrowsePhoto
            @relay(plural: true) {
                id
                serviceId
                name
                path
                serviceId
                ...usePhotosLikeDataRefetch_photos
                ...SbSharedInteriorsHeartWrapper_photo
            }
        `,
        user: graphql`
            fragment SbMobileInteriorsPhotoCarousel_user on User {
                serviceId
            }
        `,
    }
);
