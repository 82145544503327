/**
 * @generated SignedSource<<8c7ef1c8c99d83859b72e91e1c28f294>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbMobileLayout_viewer$data = {
  readonly itemSearch: {
    readonly pageType: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"SbMobileComponents_itemSearch" | "SbSharedHead_itemSearch" | "SbSharedRefetchContainer_itemSearch" | "SbSharedTrackingContainer_itemSearch" | "useSearchCorrections_itemSearch">;
  } | null;
  readonly regionalInfo?: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefetchContainer_regionalInfo">;
  } | null> | null;
  readonly shippingFilters?: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"SbMobileComponents_shippingFilters" | "SbSharedRefetchContainer_shippingFilters">;
  } | null> | null;
  readonly user?: {
    readonly " $fragmentSpreads": FragmentRefs<"SbMobileComponents_user" | "SbSharedTrackingContainer_user">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"SbMobileComponents_viewer" | "SbSharedTrackingContainer_viewer">;
  readonly " $fragmentType": "SbMobileLayout_viewer";
};
export type SbMobileLayout_viewer$key = {
  readonly " $data"?: SbMobileLayout_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbMobileLayout_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "uriRef",
  "variableName": "uriRef"
},
v1 = {
  "kind": "Variable",
  "name": "countryCode",
  "variableName": "userCountryCode"
},
v2 = {
  "kind": "Variable",
  "name": "zipCode",
  "variableName": "userZipCode"
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "disableForceFacet"
    },
    {
      "kind": "RootArgument",
      "name": "disableNonParameterizedUrlRedirects"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "fetchRegionalInfo"
    },
    {
      "kind": "RootArgument",
      "name": "fetchSb"
    },
    {
      "kind": "RootArgument",
      "name": "fetchUser"
    },
    {
      "kind": "RootArgument",
      "name": "first"
    },
    {
      "kind": "RootArgument",
      "name": "guestId"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "hasPersonalizedFilter"
    },
    {
      "kind": "RootArgument",
      "name": "includeSponsoredItems"
    },
    {
      "kind": "RootArgument",
      "name": "localeOrigin"
    },
    {
      "kind": "RootArgument",
      "name": "originalUrl"
    },
    {
      "kind": "RootArgument",
      "name": "page"
    },
    {
      "kind": "RootArgument",
      "name": "personalizationId"
    },
    {
      "kind": "RootArgument",
      "name": "priceBookName"
    },
    {
      "kind": "RootArgument",
      "name": "regions"
    },
    {
      "kind": "RootArgument",
      "name": "regionsList"
    },
    {
      "kind": "RootArgument",
      "name": "uriRef"
    },
    {
      "kind": "RootArgument",
      "name": "userCountryCode"
    },
    {
      "kind": "RootArgument",
      "name": "userId"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "userZipCode"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbMobileLayout_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbMobileComponents_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedTrackingContainer_viewer"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "disableForceFacet",
          "variableName": "disableForceFacet"
        },
        {
          "kind": "Variable",
          "name": "disableNonParameterizedUrlRedirects",
          "variableName": "disableNonParameterizedUrlRedirects"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "guestId",
          "variableName": "guestId"
        },
        {
          "kind": "Variable",
          "name": "includeSponsoredItems",
          "variableName": "includeSponsoredItems"
        },
        {
          "kind": "Variable",
          "name": "localeOrigin",
          "variableName": "localeOrigin"
        },
        {
          "kind": "Variable",
          "name": "originalUrl",
          "variableName": "originalUrl"
        },
        {
          "kind": "Variable",
          "name": "page",
          "variableName": "page"
        },
        {
          "kind": "Variable",
          "name": "priceBookName",
          "variableName": "priceBookName"
        },
        {
          "kind": "Variable",
          "name": "regions",
          "variableName": "regions"
        },
        {
          "kind": "Variable",
          "name": "regionsList",
          "variableName": "regionsList"
        },
        (v0/*: any*/),
        {
          "kind": "Variable",
          "name": "userCountryCode",
          "variableName": "userCountryCode"
        },
        {
          "kind": "Variable",
          "name": "userId",
          "variableName": "personalizationId"
        }
      ],
      "concreteType": "ItemSearchQueryConnection",
      "kind": "LinkedField",
      "name": "itemSearch",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pageType",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useSearchCorrections_itemSearch"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SbSharedRefetchContainer_itemSearch"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SbSharedTrackingContainer_itemSearch"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SbSharedHead_itemSearch"
        },
        {
          "condition": "fetchSb",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SbMobileComponents_itemSearch"
            }
          ]
        }
      ],
      "storageKey": null
    },
    {
      "condition": "fetchUser",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "userId",
              "variableName": "userId"
            }
          ],
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SbSharedTrackingContainer_user"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SbMobileComponents_user"
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "condition": "fetchRegionalInfo",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            (v1/*: any*/),
            (v2/*: any*/)
          ],
          "concreteType": "RegionalInfo",
          "kind": "LinkedField",
          "name": "regionalInfo",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SbSharedRefetchContainer_regionalInfo"
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "condition": "hasPersonalizedFilter",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            (v1/*: any*/),
            (v0/*: any*/),
            (v2/*: any*/)
          ],
          "concreteType": "ShippingFilterType",
          "kind": "LinkedField",
          "name": "shippingFilters",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SbSharedRefetchContainer_shippingFilters"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SbMobileComponents_shippingFilters"
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "3dd64028c49d59f5622fe27d1f75f446";

export default node;
