import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';

// components
import { SbSharedLink } from '../SbSharedLink/SbSharedLink';
import { Swiper } from 'dibs-pinch-zoom-swipe';
import { SbSharedCategoryTilesTracking } from '../SbSharedCategoryTilesTracking/SbSharedCategoryTilesTracking';

// utils
import { getSrcsetString } from 'dibs-image-utils/exports/srcSet';
import { createNewUriRef, getQueryParam } from '../../utils/uriUtils';
import slugify from 'dibs-slugify/exports/slugify';

// styles
import styles from './SbMobileCategoryTiles.scss';

//constants
import { SRC_SET_SIZES_MOBILE_SWIPER_THUMBNAIL_TILE } from '../../constants/imageConstants';
import { SORT } from '../SbSharedRefineMenu/sbSharedRefineMenuConstants';

const SbMobileCategoryTilesComponent = ({ itemSearch }) => {
    const { categoryTiles, displayUriRef } = itemSearch;
    const tilesClassNames = {
        item: styles.item,
        list: styles.list,
        wrapper: styles.wrapper,
    };

    if ((categoryTiles || []).length === 0) {
        return null;
    }

    const sort = getQueryParam(displayUriRef, SORT);

    return (
        <SbSharedCategoryTilesTracking itemSearch={itemSearch}>
            {({ trackClick }) => (
                <Swiper
                    dataTn="category-tiles"
                    itemsPerPage={categoryTiles.length <= 2 ? 2 : 2.5}
                    classNames={tilesClassNames}
                    shouldCategoryTilesUpdate
                    /**
                     * Whe need to re-initialize the swiper model when the children change. We know that
                     * can happen when the `displayUriRef` changes, so we just remount the components
                     * with the key hack.
                     */
                    key={displayUriRef}
                >
                    {categoryTiles.map(({ displayName, url, image }, i) => (
                        <SbSharedLink
                            // to persist the sort query in the category tile link
                            href={sort ? createNewUriRef(url, { sort }) : url}
                            className={styles.tile}
                            key={displayName}
                            onClick={() => trackClick(i)}
                            dataTn={slugify(`category-tile-${displayName}`)}
                        >
                            <span className={styles.imageWrapper}>
                                <img
                                    className={styles.image}
                                    src={image}
                                    alt={displayName}
                                    srcSet={getSrcsetString(image)}
                                    sizes={SRC_SET_SIZES_MOBILE_SWIPER_THUMBNAIL_TILE}
                                />
                            </span>
                            <span className={styles.title}>{displayName}</span>
                        </SbSharedLink>
                    ))}
                </Swiper>
            )}
        </SbSharedCategoryTilesTracking>
    );
};

SbMobileCategoryTilesComponent.propTypes = {
    itemSearch: PropTypes.shape({
        categoryTiles: PropTypes.array,
        displayUriRef: PropTypes.string,
    }),
};

export const SbMobileCategoryTiles = createFragmentContainer(SbMobileCategoryTilesComponent, {
    itemSearch: graphql`
        fragment SbMobileCategoryTiles_itemSearch on ItemSearchQueryConnection {
            ...SbSharedCategoryTilesTracking_itemSearch
            displayUriRef
            categoryTiles {
                displayName
                rank
                url
                image
            }
        }
    `,
});
