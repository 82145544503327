/* Queries */
import { sbMobileRoot as query } from '../../queries/sbMobileRoot';

/* Reducers */
import { defaultReducers, actionSanitizer } from '../../reducers/reducerUtils';
import { sbSharedReducers } from '../../reducers/sbSharedReducers';

/* Components */
import { SbMobileLayout } from '../../finding/SbMobileLayout/SbMobileLayout';

/* Helpers */
import { createEntry } from '../createEntry';
import { initializeDBLMobile } from 'dibs-buyer-layout/src/entries/index';
import { datadog, initSharedDatadogRum, sbTypes } from 'dibs-datadog/exports/datadog';
import { PAGE_TYPE } from 'dibs-constants/exports/pageTypes';
import SV from 'server-vars';
import { setDatadogABTestValues } from 'dibs-ab-tests/exports/clientAbTestV2';

const options = {
    reducers: {
        ...sbSharedReducers,
        ...defaultReducers,
    },
    state: SV.get('storeState'),
    actionSanitizer,
};

const isSearch = (SV.get('relay.variables.uriRef') || '').startsWith('/search/');
datadog.load().then(dd => {
    initSharedDatadogRum(dd, {
        device: 'mobile',
        page: PAGE_TYPE.BROWSE,
        pageSubType: isSearch ? sbTypes.search : sbTypes.browse,
    });
    setDatadogABTestValues();
});
initializeDBLMobile(options).then(({ store, actions }) => {
    createEntry({
        Container: SbMobileLayout,
        store,
        query,
        containerId: 'root',
        shouldCreateNavEntry: true,
        actions,
    });
});
