import { Suspense, type FC, useState, useRef } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import VisibilityTracker from 'dibs-visibility-tracker/exports/VisibilityTracker';
import { Button } from 'dibs-elements/exports/Button';
import { SbMobileInteriorsPhotoCarousel } from './SbMobileInteriorsPhotoCarousel';
import { SbSharedInteriorsHeader } from '../SbSharedInteriorsModule/SbSharedInteriorsHeader';
import { SbSharedInteriorsArticles } from '../SbSharedInteriorsModule/SbSharedInteriorsArticles';
import { SbSharedInteriorsPhotoModalRendererLazy } from '../SbSharedInteriorsModule/SbSharedInteriorsPhotoModal/SbSharedInteriorsPhotoModalRendererLazy';
import SbSharedInteriorsPhotoRelatedSearchLinks from '../SbSharedInteriorsModule/SbSharedInteriorsPhotoRelatedSearchLinks';
import { filterNulls } from 'dibs-ts-utils/exports/filterNulls';
import {
    trackModuleViewed,
    trackPhotoClicked,
    trackPhotoSave,
} from '../SbSharedInteriorsModule/interiorsModuleHelpers';
import { INITIAL_VISIBLE_INTERIOR_ARTICLES } from '../../constants/sbConstants';
import { viewMore, viewLess } from '../sbMessages';

import styles from './SbMobileInteriorsModule.scss';

import { type SbMobileInteriorsModule_editorial$data } from './__generated__/SbMobileInteriorsModule_editorial.graphql';
import { type SbMobileInteriorsModule_itemSearch$data } from './__generated__/SbMobileInteriorsModule_itemSearch.graphql';
import { type SbMobileInteriorsModule_user$data } from './__generated__/SbMobileInteriorsModule_user.graphql';
import { type SbMobileInteriorsModule_viewer$data } from './__generated__/SbMobileInteriorsModule_viewer.graphql';

type Props = {
    editorial: SbMobileInteriorsModule_editorial$data | null | undefined;
    itemSearch: SbMobileInteriorsModule_itemSearch$data;
    viewer: SbMobileInteriorsModule_viewer$data;
    user: SbMobileInteriorsModule_user$data;
    isClient: boolean;
};

const SbMobileInteriorsModuleComponent: FC<Props> = ({
    editorial,
    itemSearch,
    user,
    isClient,
    viewer,
}) => {
    const totalArticlesCount = editorial?.totalResults || 0;
    const interiorPhotos = itemSearch.interiorPhotos;
    const photos = (interiorPhotos?.photos || []).filter(filterNulls);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const elementRef = useRef(null);
    const [isModuleVisible, setIsModuleVisible] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    const handleVisibilityChange = ({ isVisible }: { isVisible: boolean }): void => {
        if (isVisible) {
            setIsModuleVisible(true);
            trackModuleViewed({ interiorPhotos });
        }
    };

    const onPhotoLikeAdded = (): void => {
        trackPhotoSave({ interiorPhotos });
    };

    return (
        <div className={styles.wrapper} ref={elementRef}>
            <VisibilityTracker
                onVisibilityChange={handleVisibilityChange}
                elementRef={elementRef}
            />
            <SbSharedInteriorsHeader itemSearch={itemSearch} />
            <SbMobileInteriorsPhotoCarousel
                itemSearch={itemSearch}
                photos={photos}
                onPageChange={setPhotoIndex}
                onPhotoClick={() => {
                    setIsModalOpen(true);
                    trackPhotoClicked({ interiorPhotos });
                }}
                isClient={isClient}
                onPhotoLikeAdded={onPhotoLikeAdded}
                user={user}
                isModuleVisible={isModuleVisible}
            />
            <div className={styles.relatedSearchContainer}>
                <div className={styles.relatedSearchContent}>
                    <SbSharedInteriorsPhotoRelatedSearchLinks interiorPhotos={interiorPhotos} />
                </div>
            </div>
            {viewer.isWordpressArticlesEnabled && (
                <>
                    <SbSharedInteriorsArticles
                        imgSizes="50vw"
                        editorial={editorial}
                        isExpanded={isExpanded}
                    />
                    {totalArticlesCount > INITIAL_VISIBLE_INTERIOR_ARTICLES && (
                        <div className={styles.viewMoreButton}>
                            <Button
                                type="transparent"
                                size="medium"
                                dataTn="interior-articles-view-more"
                                onClick={() => setIsExpanded(!isExpanded)}
                                fullWidth
                            >
                                {isExpanded ? viewLess : viewMore}
                            </Button>
                        </div>
                    )}
                </>
            )}

            {isModalOpen && (
                <Suspense fallback="">
                    <SbSharedInteriorsPhotoModalRendererLazy
                        photoIndex={photoIndex}
                        interiorPhotos={interiorPhotos}
                        onClose={() => setIsModalOpen(false)}
                        user={user}
                    />
                </Suspense>
            )}
        </div>
    );
};

export const SbMobileInteriorsModule = createFragmentContainer(SbMobileInteriorsModuleComponent, {
    viewer: graphql`
        fragment SbMobileInteriorsModule_viewer on Viewer {
            isWordpressArticlesEnabled: isEnabledByLocale(featureName: "WP_EDITORIAL")
        }
    `,
    itemSearch: graphql`
        fragment SbMobileInteriorsModule_itemSearch on ItemSearchQueryConnection {
            ...SbSharedInteriorsHeader_itemSearch
            ...SbMobileInteriorsPhotoCarousel_itemSearch
            interiorPhotos {
                photos {
                    ...SbMobileInteriorsPhotoCarousel_photos
                }
                ...interiorsModuleHelpers_interiorPhotos
                ...SbSharedInteriorsPhotoModalRenderer_interiorPhotos
                ...SbSharedInteriorsPhotoRelatedSearchLinks_interiorPhotos
            }
        }
    `,
    editorial: graphql`
        fragment SbMobileInteriorsModule_editorial on WordpressArticlesType {
            ...SbSharedInteriorsArticles_editorial
            totalResults
        }
    `,
    user: graphql`
        fragment SbMobileInteriorsModule_user on User {
            ...SbSharedInteriorsPhotoModalRenderer_user
            ...SbMobileInteriorsPhotoCarousel_user
        }
    `,
});
