/**
 * @generated SignedSource<<5156735ec5db5492648610c5b11be27f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbMobileComponents_viewer$data = {
  readonly isRelatedCreatorsModuleEnabled: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"SbMobileInteriorsWordpressArticlesWrapper_viewer" | "SbMobileMainContent_viewer" | "SbSharedRecentlySoldItems_viewer" | "SbSharedRecommendedItems_viewer" | "SbSharedRelatedItems_viewer" | "SbSharedTopTileController_viewer" | "SharedMobileLinksBlock_viewer" | "SharedRecentSales_viewer" | "SharedRelatedPages_viewer">;
  readonly " $fragmentType": "SbMobileComponents_viewer";
};
export type SbMobileComponents_viewer$key = {
  readonly " $data"?: SbMobileComponents_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbMobileComponents_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbMobileComponents_viewer",
  "selections": [
    {
      "alias": "isRelatedCreatorsModuleEnabled",
      "args": [
        {
          "kind": "Literal",
          "name": "feature",
          "value": "relatedCreatorsModule"
        }
      ],
      "kind": "ScalarField",
      "name": "featureFlag",
      "storageKey": "featureFlag(feature:\"relatedCreatorsModule\")"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedRelatedPages_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedMobileLinksBlock_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbMobileMainContent_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedTopTileController_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRelatedItems_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRecentlySoldItems_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbMobileInteriorsWordpressArticlesWrapper_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRecommendedItems_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedRecentSales_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "2f7b09dc1cd159b2a2931fe440944437";

export default node;
