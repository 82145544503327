/**
 * @generated SignedSource<<2b934dacce901406a1d517b970cce7aa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbMobileInteriorsModule_editorial$data = {
  readonly totalResults: number | null;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedInteriorsArticles_editorial">;
  readonly " $fragmentType": "SbMobileInteriorsModule_editorial";
};
export type SbMobileInteriorsModule_editorial$key = {
  readonly " $data"?: SbMobileInteriorsModule_editorial$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbMobileInteriorsModule_editorial">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbMobileInteriorsModule_editorial",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedInteriorsArticles_editorial"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalResults",
      "storageKey": null
    }
  ],
  "type": "WordpressArticlesType",
  "abstractKey": null
};

(node as any).hash = "23d494d760f48cd263500cb7edcb8276";

export default node;
