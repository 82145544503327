/**
 * @generated SignedSource<<ea1e802eb82a8c57067a9c9e14f62d95>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbMobileInteriorsPhotoCarousel_user$data = {
  readonly serviceId: string | null;
  readonly " $fragmentType": "SbMobileInteriorsPhotoCarousel_user";
};
export type SbMobileInteriorsPhotoCarousel_user$key = {
  readonly " $data"?: SbMobileInteriorsPhotoCarousel_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbMobileInteriorsPhotoCarousel_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbMobileInteriorsPhotoCarousel_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceId",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "e4b21d4ef099d4516349f09f38da49c9";

export default node;
