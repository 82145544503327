/**
 * @generated SignedSource<<9738497cc7dd3cf7ec68c7ac7d9f1b97>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbMobileInteriorsModule_itemSearch$data = {
  readonly interiorPhotos: {
    readonly photos: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"SbMobileInteriorsPhotoCarousel_photos">;
    } | null> | null;
    readonly " $fragmentSpreads": FragmentRefs<"SbSharedInteriorsPhotoModalRenderer_interiorPhotos" | "SbSharedInteriorsPhotoRelatedSearchLinks_interiorPhotos" | "interiorsModuleHelpers_interiorPhotos">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"SbMobileInteriorsPhotoCarousel_itemSearch" | "SbSharedInteriorsHeader_itemSearch">;
  readonly " $fragmentType": "SbMobileInteriorsModule_itemSearch";
};
export type SbMobileInteriorsModule_itemSearch$key = {
  readonly " $data"?: SbMobileInteriorsModule_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbMobileInteriorsModule_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbMobileInteriorsModule_itemSearch",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedInteriorsHeader_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbMobileInteriorsPhotoCarousel_itemSearch"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SearchBrowseInteriorPhotosModule",
      "kind": "LinkedField",
      "name": "interiorPhotos",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PhotosSearchBrowsePhoto",
          "kind": "LinkedField",
          "name": "photos",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SbMobileInteriorsPhotoCarousel_photos"
            }
          ],
          "storageKey": null
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "interiorsModuleHelpers_interiorPhotos",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "InteriorPhotosModulePhotoSearchParam",
              "kind": "LinkedField",
              "name": "photoSearchParam",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SbSharedInteriorsPhotoModalRenderer_interiorPhotos"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SbSharedInteriorsPhotoRelatedSearchLinks_interiorPhotos"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "10d295f1bc1efe19e823c605dfe4fff7";

export default node;
