/**
 * @generated SignedSource<<7f9287b793b6b25e5dbbf4cbbde8792a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbMobileInteriorsModule_viewer$data = {
  readonly isWordpressArticlesEnabled: boolean | null;
  readonly " $fragmentType": "SbMobileInteriorsModule_viewer";
};
export type SbMobileInteriorsModule_viewer$key = {
  readonly " $data"?: SbMobileInteriorsModule_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbMobileInteriorsModule_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbMobileInteriorsModule_viewer",
  "selections": [
    {
      "alias": "isWordpressArticlesEnabled",
      "args": [
        {
          "kind": "Literal",
          "name": "featureName",
          "value": "WP_EDITORIAL"
        }
      ],
      "kind": "ScalarField",
      "name": "isEnabledByLocale",
      "storageKey": "isEnabledByLocale(featureName:\"WP_EDITORIAL\")"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "57e65a235cd22f81e7c8779add36fea7";

export default node;
