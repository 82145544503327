/**
 * @generated SignedSource<<760cdef4d586dcde73180a311d520544>>
 * @relayHash f2fd351ce6650f87761e90f8011d28d4
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-finding/505.0.0-2025-04-08T14:45:28.242Z/SbMobileLayoutRefetchQuery

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PageDisplayEnum = "auctionsSearchAndBrowse" | "cart" | "catalog" | "checkout" | "email" | "favorites" | "homepage" | "mc" | "pdp" | "publication" | "searchAndBrowse" | "similarSold" | "visuallySimilar" | "%future added value";
export type types = "DESIGNER" | "SEARCH" | "SEARCH_HISTORY" | "SELLER" | "USER_PREFERENCE" | "%future added value";
export type SbMobileLayoutRefetchQuery$variables = {
  contentModuleId?: string | null;
  disableForceFacet?: boolean | null;
  disableNonParameterizedUrlRedirects?: boolean | null;
  fetchRegionalInfo?: boolean | null;
  fetchSb: boolean;
  fetchTileVideo?: boolean | null;
  fetchUser: boolean;
  first: number;
  followSearchPages?: ReadonlyArray<string | null> | null;
  followSearchTypes?: ReadonlyArray<types | null> | null;
  guestId?: string | null;
  hasPersonalizedFilter: boolean;
  includeSponsoredItems?: boolean | null;
  isClient: boolean;
  isTrade: boolean;
  localeOrigin?: string | null;
  originalUrl?: string | null;
  page: number;
  pageDisplayEnum?: PageDisplayEnum | null;
  personalizationId?: string | null;
  priceBookName?: string | null;
  regions?: string | null;
  regionsList?: ReadonlyArray<string | null> | null;
  rerankGuestId?: string | null;
  rerankUserId?: string | null;
  showSeller: boolean;
  skipUser?: boolean | null;
  uriRef?: string | null;
  userCountryCode?: string | null;
  userId?: string | null;
  userZipCode?: string | null;
};
export type SbMobileLayoutRefetchQuery$data = {
  readonly viewer: {
    readonly itemSearch?: {
      readonly pageType: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"SbMobileComponents_itemSearch" | "SbSharedHead_itemSearch" | "SbSharedRefetchContainer_itemSearch" | "SbSharedTrackingContainer_itemSearch">;
    } | null;
    readonly regionalInfo?: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefetchContainer_regionalInfo">;
    } | null> | null;
    readonly shippingFilters?: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"SbMobileComponents_shippingFilters" | "SbSharedRefetchContainer_shippingFilters">;
    } | null> | null;
    readonly user?: {
      readonly " $fragmentSpreads": FragmentRefs<"SbMobileComponents_user" | "SbSharedTrackingContainer_user">;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"SbMobileComponents_viewer" | "SbSharedTrackingContainer_viewer">;
  };
};
export type SbMobileLayoutRefetchQuery = {
  response: SbMobileLayoutRefetchQuery$data;
  variables: SbMobileLayoutRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "contentModuleId"
},
v1 = {
  "defaultValue": true,
  "kind": "LocalArgument",
  "name": "disableForceFacet"
},
v2 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "disableNonParameterizedUrlRedirects"
},
v3 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "fetchRegionalInfo"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fetchSb"
},
v5 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "fetchTileVideo"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fetchUser"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "followSearchPages"
},
v9 = {
  "defaultValue": [
    "SEARCH",
    "USER_PREFERENCE"
  ],
  "kind": "LocalArgument",
  "name": "followSearchTypes"
},
v10 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "guestId"
},
v11 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasPersonalizedFilter"
},
v12 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "includeSponsoredItems"
},
v13 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isClient"
},
v14 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isTrade"
},
v15 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "localeOrigin"
},
v16 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "originalUrl"
},
v17 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "page"
},
v18 = {
  "defaultValue": "searchAndBrowse",
  "kind": "LocalArgument",
  "name": "pageDisplayEnum"
},
v19 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "personalizationId"
},
v20 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "priceBookName"
},
v21 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "regions"
},
v22 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "regionsList"
},
v23 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "rerankGuestId"
},
v24 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "rerankUserId"
},
v25 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "showSeller"
},
v26 = {
  "defaultValue": true,
  "kind": "LocalArgument",
  "name": "skipUser"
},
v27 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "uriRef"
},
v28 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "userCountryCode"
},
v29 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "userId"
},
v30 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "userZipCode"
},
v31 = {
  "kind": "Variable",
  "name": "priceBookName",
  "variableName": "priceBookName"
},
v32 = {
  "kind": "Variable",
  "name": "uriRef",
  "variableName": "uriRef"
},
v33 = {
  "kind": "Variable",
  "name": "userCountryCode",
  "variableName": "userCountryCode"
},
v34 = [
  {
    "kind": "Variable",
    "name": "disableForceFacet",
    "variableName": "disableForceFacet"
  },
  {
    "kind": "Variable",
    "name": "disableNonParameterizedUrlRedirects",
    "variableName": "disableNonParameterizedUrlRedirects"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "guestId",
    "variableName": "guestId"
  },
  {
    "kind": "Variable",
    "name": "includeSponsoredItems",
    "variableName": "includeSponsoredItems"
  },
  {
    "kind": "Variable",
    "name": "localeOrigin",
    "variableName": "localeOrigin"
  },
  {
    "kind": "Variable",
    "name": "originalUrl",
    "variableName": "originalUrl"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  (v31/*: any*/),
  {
    "kind": "Variable",
    "name": "regions",
    "variableName": "regions"
  },
  {
    "kind": "Variable",
    "name": "regionsList",
    "variableName": "regionsList"
  },
  (v32/*: any*/),
  (v33/*: any*/),
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "personalizationId"
  }
],
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pageType",
  "storageKey": null
},
v36 = [
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "userId"
  }
],
v37 = {
  "kind": "Variable",
  "name": "countryCode",
  "variableName": "userCountryCode"
},
v38 = {
  "kind": "Variable",
  "name": "zipCode",
  "variableName": "userZipCode"
},
v39 = [
  (v37/*: any*/),
  (v38/*: any*/)
],
v40 = [
  (v37/*: any*/),
  (v32/*: any*/),
  (v38/*: any*/)
],
v41 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "width",
  "storageKey": null
},
v42 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "link",
  "storageKey": null
},
v43 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v44 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contemporaryTrackingString",
  "storageKey": null
},
v45 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v46 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "browseUrl",
  "storageKey": null
},
v47 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "categoryCode",
  "storageKey": null
},
v48 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v49 = {
  "alias": null,
  "args": null,
  "concreteType": "Seller",
  "kind": "LinkedField",
  "name": "seller",
  "plural": false,
  "selections": [
    (v43/*: any*/),
    (v48/*: any*/)
  ],
  "storageKey": null
},
v50 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ecommerceTrackingParams",
  "storageKey": null
},
v51 = {
  "alias": null,
  "args": null,
  "concreteType": "pdpMetaItems",
  "kind": "LinkedField",
  "name": "pdpMeta",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "topQuery",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v52 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "localizedPdpUrl",
  "storageKey": null
},
v53 = {
  "alias": null,
  "args": null,
  "concreteType": "ItemClassification",
  "kind": "LinkedField",
  "name": "classification",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "creationDate",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v54 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v55 = {
  "alias": null,
  "args": null,
  "concreteType": "ItemCreator",
  "kind": "LinkedField",
  "name": "creators",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "attribution",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Creator",
      "kind": "LinkedField",
      "name": "creator",
      "plural": false,
      "selections": [
        (v54/*: any*/),
        (v48/*: any*/)
      ],
      "storageKey": null
    },
    (v48/*: any*/)
  ],
  "storageKey": null
},
v56 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isArt",
  "storageKey": null
},
v57 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "path",
  "storageKey": null
},
v58 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isLinkable",
  "storageKey": null
},
v59 = [
  (v57/*: any*/),
  (v58/*: any*/)
],
v60 = {
  "alias": null,
  "args": null,
  "concreteType": "LinkData",
  "kind": "LinkedField",
  "name": "linkData",
  "plural": false,
  "selections": (v59/*: any*/),
  "storageKey": null
},
v61 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isStorefrontOnly",
  "storageKey": null
},
v62 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isMultiSku",
  "storageKey": null
},
v63 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isRingItem",
  "storageKey": null
},
v64 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageUrl",
  "storageKey": null
},
v65 = {
  "alias": null,
  "args": null,
  "concreteType": "VariableAttribute",
  "kind": "LinkedField",
  "name": "variableAttributes",
  "plural": true,
  "selections": [
    (v43/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "VariableAttributeSortType",
      "kind": "LinkedField",
      "name": "variableAttributeData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SwatchSort",
          "kind": "LinkedField",
          "name": "swatchSort",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Swatch",
              "kind": "LinkedField",
              "name": "swatch",
              "plural": false,
              "selections": [
                (v43/*: any*/),
                (v54/*: any*/),
                (v64/*: any*/),
                (v48/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v48/*: any*/)
  ],
  "storageKey": null
},
v66 = {
  "kind": "Literal",
  "name": "page",
  "value": "searchAndBrowse"
},
v67 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v68 = {
  "alias": null,
  "args": null,
  "concreteType": "ConvertedAmountList",
  "kind": "LinkedField",
  "name": "convertedAmountList",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amount",
      "storageKey": null
    },
    (v67/*: any*/)
  ],
  "storageKey": null
},
v69 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "textType",
  "storageKey": null
},
v70 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amountType",
  "storageKey": null
},
v71 = [
  (v68/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "quantityDisplay",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "percentageOff",
    "storageKey": null
  },
  (v69/*: any*/),
  (v70/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "showPriceLabel",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "showPriceVariability",
    "storageKey": null
  }
],
v72 = [
  {
    "kind": "Literal",
    "name": "formatType",
    "value": "MOBILE"
  }
],
v73 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v74 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v75 = {
  "alias": null,
  "args": null,
  "concreteType": "QuickViewDisplayType",
  "kind": "LinkedField",
  "name": "quickViewDisplay",
  "plural": false,
  "selections": [
    (v45/*: any*/),
    {
      "alias": "mobileTitle",
      "args": (v72/*: any*/),
      "kind": "ScalarField",
      "name": "title",
      "storageKey": "title(formatType:\"MOBILE\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "QuickViewParagraph",
      "kind": "LinkedField",
      "name": "paragraphs",
      "plural": true,
      "selections": [
        (v73/*: any*/),
        (v74/*: any*/),
        {
          "alias": "mobileText",
          "args": (v72/*: any*/),
          "kind": "ScalarField",
          "name": "text",
          "storageKey": "text(formatType:\"MOBILE\")"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "QuickViewCreator",
      "kind": "LinkedField",
      "name": "creators",
      "plural": true,
      "selections": [
        (v54/*: any*/),
        (v60/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "QuickViewCategorySegments",
      "kind": "LinkedField",
      "name": "paragraphAttributeNames",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "period",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "origin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "style",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "periodPrefix",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v76 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v77 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "unit",
    "storageKey": null
  },
  (v76/*: any*/)
],
v78 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "addLabel",
      "value": true
    },
    {
      "kind": "Literal",
      "name": "fields",
      "value": [
        "height",
        "width",
        "depth",
        "diameter",
        "length"
      ]
    },
    {
      "kind": "Literal",
      "name": "separator",
      "value": " "
    }
  ],
  "concreteType": "ItemDisplayMeasurementType",
  "kind": "LinkedField",
  "name": "display",
  "plural": true,
  "selections": (v77/*: any*/),
  "storageKey": "display(addLabel:true,fields:[\"height\",\"width\",\"depth\",\"diameter\",\"length\"],separator:\" \")"
},
v79 = {
  "alias": null,
  "args": null,
  "concreteType": "ItemSize",
  "kind": "LinkedField",
  "name": "size",
  "plural": false,
  "selections": [
    (v76/*: any*/)
  ],
  "storageKey": null
},
v80 = {
  "alias": null,
  "args": null,
  "concreteType": "ItemMeasurement",
  "kind": "LinkedField",
  "name": "measurement",
  "plural": false,
  "selections": [
    (v78/*: any*/),
    (v79/*: any*/)
  ],
  "storageKey": null
},
v81 = [
  {
    "kind": "Literal",
    "name": "limit",
    "value": 1
  }
],
v82 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "placeholder",
  "storageKey": null
},
v83 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "smallPath",
  "storageKey": null
},
v84 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "masterOrZoomPath",
  "storageKey": null
},
v85 = {
  "alias": null,
  "args": null,
  "concreteType": "ItemPhotoVersion",
  "kind": "LinkedField",
  "name": "versions",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "webPath",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v86 = {
  "alias": "productPhotos",
  "args": (v81/*: any*/),
  "concreteType": "ItemPhoto",
  "kind": "LinkedField",
  "name": "photos",
  "plural": true,
  "selections": [
    (v82/*: any*/),
    (v83/*: any*/),
    (v84/*: any*/),
    (v85/*: any*/),
    (v48/*: any*/)
  ],
  "storageKey": "photos(limit:1)"
},
v87 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
},
v88 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "urlLabel",
  "storageKey": null
},
v89 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "linkReference",
  "storageKey": null
},
v90 = [
  (v54/*: any*/)
],
v91 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "regionName",
  "storageKey": null
},
v92 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalResults",
  "storageKey": null
},
v93 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v94 = {
  "alias": "isRewarded",
  "args": [
    {
      "kind": "Literal",
      "name": "listNames",
      "value": [
        "SELLER_REWARDS_1",
        "SELLER_REWARDS_2"
      ]
    }
  ],
  "kind": "ScalarField",
  "name": "isAnchorListsMember",
  "storageKey": "isAnchorListsMember(listNames:[\"SELLER_REWARDS_1\",\"SELLER_REWARDS_2\"])"
},
v95 = {
  "alias": null,
  "args": null,
  "concreteType": "SellerProfile",
  "kind": "LinkedField",
  "name": "sellerProfile",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "company",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v96 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v97 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subtitle",
  "storageKey": null
},
v98 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v99 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v100 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hexCode",
  "storageKey": null
},
v101 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "us",
  "storageKey": null
},
v102 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mm",
  "storageKey": null
},
v103 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "localizedFilterName",
  "storageKey": null
},
v104 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "itemId",
  "storageKey": null
},
v105 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v106 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "searchTerm",
  "storageKey": null
},
v107 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isSold",
  "storageKey": null
},
v108 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isOnHold",
  "storageKey": null
},
v109 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isUnavailable",
  "storageKey": null
},
v110 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "itemType",
  "storageKey": null
},
v111 = {
  "alias": null,
  "args": null,
  "concreteType": "Seller",
  "kind": "LinkedField",
  "name": "seller",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isAccessible",
      "storageKey": null
    },
    (v48/*: any*/),
    (v43/*: any*/)
  ],
  "storageKey": null
},
v112 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isComplimentary",
  "storageKey": null
},
v113 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isCalculated",
  "storageKey": null
},
v114 = {
  "alias": null,
  "args": null,
  "concreteType": "FinancialAmount",
  "kind": "LinkedField",
  "name": "totalAmount",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ConvertedAmounts",
      "kind": "LinkedField",
      "name": "convertedAmounts",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "USD",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v115 = {
  "alias": null,
  "args": null,
  "concreteType": "ShipmentQuote",
  "kind": "LinkedField",
  "name": "shipmentQuotes",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ShipmentServiceMethod",
      "kind": "LinkedField",
      "name": "serviceMethod",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Region",
          "kind": "LinkedField",
          "name": "region",
          "plural": false,
          "selections": [
            (v99/*: any*/),
            (v48/*: any*/),
            (v91/*: any*/)
          ],
          "storageKey": null
        },
        (v48/*: any*/)
      ],
      "storageKey": null
    },
    (v48/*: any*/),
    (v112/*: any*/),
    (v113/*: any*/),
    (v114/*: any*/)
  ],
  "storageKey": null
},
v116 = {
  "kind": "Variable",
  "name": "isTrade",
  "variableName": "isTrade"
},
v117 = {
  "alias": "displayPriceTracking",
  "args": [
    (v116/*: any*/),
    {
      "kind": "Variable",
      "name": "page",
      "variableName": "pageDisplayEnum"
    },
    (v31/*: any*/),
    (v33/*: any*/)
  ],
  "concreteType": "ItemDisplayPriceType",
  "kind": "LinkedField",
  "name": "displayPrice",
  "plural": true,
  "selections": [
    (v68/*: any*/),
    (v70/*: any*/),
    (v69/*: any*/)
  ],
  "storageKey": null
},
v118 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isNewListing",
  "storageKey": null
},
v119 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "personalizationType",
  "storageKey": null
},
v120 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vertical",
  "storageKey": null
},
v121 = {
  "alias": null,
  "args": [
    (v116/*: any*/),
    (v66/*: any*/),
    (v31/*: any*/)
  ],
  "concreteType": "ItemDisplayPriceType",
  "kind": "LinkedField",
  "name": "displayPrice",
  "plural": true,
  "selections": (v71/*: any*/),
  "storageKey": null
},
v122 = {
  "kind": "Literal",
  "name": "addLabel",
  "value": false
},
v123 = {
  "alias": null,
  "args": null,
  "concreteType": "ItemMeasurement",
  "kind": "LinkedField",
  "name": "measurement",
  "plural": false,
  "selections": [
    (v78/*: any*/),
    (v79/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "unit",
          "value": "IN"
        }
      ],
      "concreteType": "ItemConvertedMeasurementsType",
      "kind": "LinkedField",
      "name": "convertedMeasurements",
      "plural": false,
      "selections": [
        (v41/*: any*/)
      ],
      "storageKey": "convertedMeasurements(unit:\"IN\")"
    },
    {
      "alias": "widthDimensions",
      "args": [
        (v122/*: any*/),
        {
          "kind": "Literal",
          "name": "fields",
          "value": [
            "width"
          ]
        }
      ],
      "concreteType": "ItemDisplayMeasurementType",
      "kind": "LinkedField",
      "name": "display",
      "plural": true,
      "selections": (v77/*: any*/),
      "storageKey": "display(addLabel:false,fields:[\"width\"])"
    },
    {
      "alias": "heightDimensions",
      "args": [
        (v122/*: any*/),
        {
          "kind": "Literal",
          "name": "fields",
          "value": [
            "height"
          ]
        }
      ],
      "concreteType": "ItemDisplayMeasurementType",
      "kind": "LinkedField",
      "name": "display",
      "plural": true,
      "selections": (v77/*: any*/),
      "storageKey": "display(addLabel:false,fields:[\"height\"])"
    }
  ],
  "storageKey": null
},
v124 = {
  "alias": "carouselPhotos",
  "args": [
    {
      "kind": "Literal",
      "name": "limit",
      "value": 2
    }
  ],
  "concreteType": "ItemPhoto",
  "kind": "LinkedField",
  "name": "photos",
  "plural": true,
  "selections": [
    (v93/*: any*/),
    (v48/*: any*/),
    (v82/*: any*/),
    (v83/*: any*/),
    (v84/*: any*/)
  ],
  "storageKey": "photos(limit:2)"
},
v125 = {
  "alias": null,
  "args": (v81/*: any*/),
  "concreteType": "viewInRoomPhotos",
  "kind": "LinkedField",
  "name": "viewInRoomPhotos",
  "plural": true,
  "selections": [
    (v93/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "src",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "viewInRoomPhotosCenterPosition",
      "kind": "LinkedField",
      "name": "centerPosition",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "top",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "left",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "viewInRoomPhotosComparatorWidth",
      "kind": "LinkedField",
      "name": "comparatorWidth",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pixel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "inches",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "viewInRoomPhotos(limit:1)"
},
v126 = {
  "alias": "productPhotos",
  "args": (v81/*: any*/),
  "concreteType": "ItemPhoto",
  "kind": "LinkedField",
  "name": "photos",
  "plural": true,
  "selections": [
    (v83/*: any*/),
    (v85/*: any*/),
    (v48/*: any*/)
  ],
  "storageKey": "photos(limit:1)"
},
v127 = {
  "condition": "showSeller",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Seller",
      "kind": "LinkedField",
      "name": "seller",
      "plural": false,
      "selections": [
        (v95/*: any*/)
      ],
      "storageKey": null
    }
  ]
},
v128 = {
  "condition": "fetchTileVideo",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasApprovedVideo",
      "storageKey": null
    }
  ]
},
v129 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 28
  }
],
v130 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "anchorText",
  "storageKey": null
},
v131 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "values",
  "storageKey": null
},
v132 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v133 = [
  (v105/*: any*/),
  (v130/*: any*/),
  (v48/*: any*/)
],
v134 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 8
  }
],
v135 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publishedDate",
  "storageKey": null
},
v136 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "full",
  "storageKey": null
},
v137 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "source",
  "storageKey": null
},
v138 = {
  "alias": null,
  "args": null,
  "concreteType": "ShipmentQuote",
  "kind": "LinkedField",
  "name": "shipmentQuotes",
  "plural": true,
  "selections": [
    (v112/*: any*/),
    (v113/*: any*/),
    (v114/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ShipmentServiceMethod",
      "kind": "LinkedField",
      "name": "serviceMethod",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Region",
          "kind": "LinkedField",
          "name": "region",
          "plural": false,
          "selections": [
            (v91/*: any*/),
            (v48/*: any*/)
          ],
          "storageKey": null
        },
        (v48/*: any*/)
      ],
      "storageKey": null
    },
    (v48/*: any*/)
  ],
  "storageKey": null
},
v139 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isFurniture",
  "storageKey": null
},
v140 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "zipCode",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "country",
    "storageKey": null
  },
  (v48/*: any*/)
],
v141 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  },
  {
    "kind": "Variable",
    "name": "pages",
    "variableName": "followSearchPages"
  },
  {
    "kind": "Variable",
    "name": "types",
    "variableName": "followSearchTypes"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/),
      (v15/*: any*/),
      (v16/*: any*/),
      (v17/*: any*/),
      (v18/*: any*/),
      (v19/*: any*/),
      (v20/*: any*/),
      (v21/*: any*/),
      (v22/*: any*/),
      (v23/*: any*/),
      (v24/*: any*/),
      (v25/*: any*/),
      (v26/*: any*/),
      (v27/*: any*/),
      (v28/*: any*/),
      (v29/*: any*/),
      (v30/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SbMobileLayoutRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SbSharedTrackingContainer_viewer"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SbMobileComponents_viewer"
          },
          {
            "condition": "fetchSb",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": (v34/*: any*/),
                "concreteType": "ItemSearchQueryConnection",
                "kind": "LinkedField",
                "name": "itemSearch",
                "plural": false,
                "selections": [
                  (v35/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "SbSharedRefetchContainer_itemSearch"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "SbSharedTrackingContainer_itemSearch"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "SbSharedHead_itemSearch"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "SbMobileComponents_itemSearch"
                  }
                ],
                "storageKey": null
              }
            ]
          },
          {
            "condition": "skipUser",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "condition": "fetchUser",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": (v36/*: any*/),
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "SbMobileComponents_user"
                      },
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "SbSharedTrackingContainer_user"
                      }
                    ],
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "condition": "fetchRegionalInfo",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": (v39/*: any*/),
                "concreteType": "RegionalInfo",
                "kind": "LinkedField",
                "name": "regionalInfo",
                "plural": true,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "SbSharedRefetchContainer_regionalInfo"
                  }
                ],
                "storageKey": null
              }
            ]
          },
          {
            "condition": "hasPersonalizedFilter",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": (v40/*: any*/),
                "concreteType": "ShippingFilterType",
                "kind": "LinkedField",
                "name": "shippingFilters",
                "plural": true,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "SbSharedRefetchContainer_shippingFilters"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "SbMobileComponents_shippingFilters"
                  }
                ],
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v29/*: any*/),
      (v19/*: any*/),
      (v10/*: any*/),
      (v6/*: any*/),
      (v24/*: any*/),
      (v23/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v27/*: any*/),
      (v16/*: any*/),
      (v7/*: any*/),
      (v17/*: any*/),
      (v15/*: any*/),
      (v25/*: any*/),
      (v12/*: any*/),
      (v14/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v26/*: any*/),
      (v0/*: any*/),
      (v21/*: any*/),
      (v11/*: any*/),
      (v30/*: any*/),
      (v28/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v13/*: any*/),
      (v2/*: any*/),
      (v20/*: any*/),
      (v22/*: any*/),
      (v18/*: any*/)
    ],
    "kind": "Operation",
    "name": "SbMobileLayoutRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": "isRelatedCreatorsModuleEnabled",
            "args": [
              {
                "kind": "Literal",
                "name": "feature",
                "value": "relatedCreatorsModule"
              }
            ],
            "kind": "ScalarField",
            "name": "featureFlag",
            "storageKey": "featureFlag(feature:\"relatedCreatorsModule\")"
          },
          {
            "alias": "isPopularSearchesEnabled",
            "args": [
              {
                "kind": "Literal",
                "name": "feature",
                "value": "popularSearches"
              }
            ],
            "kind": "ScalarField",
            "name": "featureFlag",
            "storageKey": "featureFlag(feature:\"popularSearches\")"
          },
          {
            "alias": "video",
            "args": null,
            "concreteType": "VideoVimeo",
            "kind": "LinkedField",
            "name": "itemVideo",
            "plural": false,
            "selections": [
              {
                "alias": "videoThumb",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "pad",
                    "value": false
                  },
                  {
                    "kind": "Literal",
                    "name": "size",
                    "value": "w295h166"
                  }
                ],
                "kind": "ScalarField",
                "name": "thumbnail",
                "storageKey": "thumbnail(pad:false,size:\"w295h166\")"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "height",
                "storageKey": null
              },
              (v41/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "VideoVimeoFile",
                "kind": "LinkedField",
                "name": "fileByVideoSize",
                "plural": false,
                "selections": [
                  (v42/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "showCreatorDesignTiles",
            "args": [
              {
                "kind": "Literal",
                "name": "feature",
                "value": "sbCreatorDesignTiles"
              }
            ],
            "kind": "ScalarField",
            "name": "featureFlag",
            "storageKey": "featureFlag(feature:\"sbCreatorDesignTiles\")"
          },
          {
            "alias": "isWordpressArticlesEnabled",
            "args": [
              {
                "kind": "Literal",
                "name": "featureName",
                "value": "WP_EDITORIAL"
              }
            ],
            "kind": "ScalarField",
            "name": "isEnabledByLocale",
            "storageKey": "isEnabledByLocale(featureName:\"WP_EDITORIAL\")"
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "pageSize",
                "value": 18
              },
              {
                "kind": "Literal",
                "name": "recentlyViewedIds",
                "value": []
              },
              {
                "kind": "Literal",
                "name": "returnAmount",
                "value": 12
              },
              {
                "kind": "Literal",
                "name": "userIds",
                "value": []
              }
            ],
            "concreteType": "Item",
            "kind": "LinkedField",
            "name": "recommendedItems",
            "plural": true,
            "selections": [
              (v43/*: any*/),
              (v44/*: any*/),
              (v45/*: any*/),
              (v46/*: any*/),
              (v47/*: any*/),
              (v49/*: any*/),
              (v50/*: any*/),
              (v51/*: any*/),
              (v52/*: any*/),
              (v53/*: any*/),
              (v55/*: any*/),
              (v56/*: any*/),
              (v60/*: any*/),
              (v61/*: any*/),
              (v62/*: any*/),
              (v63/*: any*/),
              (v65/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "isTrade",
                    "value": false
                  },
                  (v66/*: any*/)
                ],
                "concreteType": "ItemDisplayPriceType",
                "kind": "LinkedField",
                "name": "displayPrice",
                "plural": true,
                "selections": (v71/*: any*/),
                "storageKey": "displayPrice(isTrade:false,page:\"searchAndBrowse\")"
              },
              (v75/*: any*/),
              (v80/*: any*/),
              (v86/*: any*/),
              (v48/*: any*/)
            ],
            "storageKey": "recommendedItems(pageSize:18,recentlyViewedIds:[],returnAmount:12,userIds:[])"
          },
          (v48/*: any*/),
          {
            "condition": "isClient",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "guestId",
                    "variableName": "rerankGuestId"
                  },
                  {
                    "kind": "Variable",
                    "name": "userId",
                    "variableName": "rerankUserId"
                  }
                ],
                "kind": "ScalarField",
                "name": "isEligibleForRerank",
                "storageKey": null
              },
              {
                "alias": "locations",
                "args": [
                  (v37/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "facetLimit",
                    "value": 10
                  },
                  {
                    "kind": "Literal",
                    "name": "facetName",
                    "value": "location"
                  },
                  (v32/*: any*/),
                  (v38/*: any*/)
                ],
                "concreteType": "ItemFacetsQueryConnection",
                "kind": "LinkedField",
                "name": "itemFacetSearch",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ItemFacetsQueryEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "FilterValueType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v87/*: any*/),
                          (v54/*: any*/),
                          (v88/*: any*/),
                          (v89/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ]
          },
          {
            "condition": "fetchRegionalInfo",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": "regionsInfo",
                "args": (v39/*: any*/),
                "concreteType": "RegionalInfo",
                "kind": "LinkedField",
                "name": "regionalInfo",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RegionsByZipCode",
                    "kind": "LinkedField",
                    "name": "regionsByZipCode",
                    "plural": true,
                    "selections": (v90/*: any*/),
                    "storageKey": null
                  },
                  (v48/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v39/*: any*/),
                "concreteType": "RegionalInfo",
                "kind": "LinkedField",
                "name": "regionalInfo",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RegionsByZipCode",
                    "kind": "LinkedField",
                    "name": "regionsByZipCode",
                    "plural": true,
                    "selections": [
                      (v54/*: any*/),
                      (v88/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v48/*: any*/)
                ],
                "storageKey": null
              }
            ]
          },
          {
            "condition": "isTrade",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "address",
                    "value": {
                      "country": "us"
                    }
                  },
                  {
                    "kind": "Literal",
                    "name": "regionBoundaryTypes",
                    "value": [
                      "METROPOLITAN",
                      "DISTRICT",
                      "DOMESTIC"
                    ]
                  },
                  {
                    "kind": "Literal",
                    "name": "regionType",
                    "value": "ITEM_LOCATION"
                  }
                ],
                "concreteType": "Region",
                "kind": "LinkedField",
                "name": "regions",
                "plural": true,
                "selections": [
                  (v91/*: any*/),
                  (v88/*: any*/),
                  (v48/*: any*/)
                ],
                "storageKey": "regions(address:{\"country\":\"us\"},regionBoundaryTypes:[\"METROPOLITAN\",\"DISTRICT\",\"DOMESTIC\"],regionType:\"ITEM_LOCATION\")"
              }
            ]
          },
          {
            "condition": "fetchSb",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": (v34/*: any*/),
                "concreteType": "ItemSearchQueryConnection",
                "kind": "LinkedField",
                "name": "itemSearch",
                "plural": false,
                "selections": [
                  (v35/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "vanityUriRef",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayUriRef",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "topCategoryL1",
                    "storageKey": null
                  },
                  (v92/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "appliedPersonalizationList",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "contentModuleId",
                        "variableName": "contentModuleId"
                      }
                    ],
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "browseContentModule",
                    "plural": false,
                    "selections": [
                      (v93/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "moduleType",
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Seller",
                            "kind": "LinkedField",
                            "name": "seller",
                            "plural": false,
                            "selections": [
                              (v94/*: any*/),
                              (v95/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "SellerPreferences",
                                "kind": "LinkedField",
                                "name": "sellerPreferences",
                                "plural": false,
                                "selections": [
                                  (v88/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "StorefrontProfile",
                                "kind": "LinkedField",
                                "name": "liveStorefrontProfile",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "status",
                                    "storageKey": null
                                  },
                                  (v48/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v48/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v96/*: any*/)
                        ],
                        "type": "ContentModuleRegular",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "photo",
                            "storageKey": null
                          },
                          (v45/*: any*/),
                          (v97/*: any*/),
                          (v96/*: any*/),
                          (v42/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "linkText",
                            "storageKey": null
                          }
                        ],
                        "type": "ContentModuleBanner",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "termsLink",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "BrowseContentModuleBannerType",
                            "kind": "LinkedField",
                            "name": "banners",
                            "plural": true,
                            "selections": [
                              (v45/*: any*/),
                              (v96/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "eyeBrowText",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "ctaUrl",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "ctaCopy",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "ctaStyle",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "textAlignment",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "textColor",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "backgroundColor",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "mainImage",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "format",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "textPosition",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "bannerImageWidth",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "BrowseContentModuleTile",
                            "kind": "LinkedField",
                            "name": "tiles",
                            "plural": true,
                            "selections": [
                              (v64/*: any*/),
                              (v45/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "content",
                                "storageKey": null
                              },
                              (v60/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "LocalizedLinkData",
                                "kind": "LinkedField",
                                "name": "localizedLinkDataList",
                                "plural": true,
                                "selections": [
                                  (v67/*: any*/),
                                  (v60/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "ContentModuleBannerTiles",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SearchBrowseParsedParam",
                    "kind": "LinkedField",
                    "name": "appliedFilters",
                    "plural": true,
                    "selections": [
                      (v98/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SearchBrowseParsedParamValue",
                        "kind": "LinkedField",
                        "name": "values",
                        "plural": true,
                        "selections": [
                          (v54/*: any*/),
                          (v88/*: any*/),
                          (v99/*: any*/),
                          (v89/*: any*/),
                          (v100/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "FilterPropertiesType",
                            "kind": "LinkedField",
                            "name": "properties",
                            "plural": false,
                            "selections": [
                              (v101/*: any*/),
                              (v102/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v87/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v103/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "canBeDismissed",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "clearAllLinkReference",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SortType",
                    "kind": "LinkedField",
                    "name": "sort",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SearchBrowseSortCurrentOption",
                        "kind": "LinkedField",
                        "name": "currentOption",
                        "plural": false,
                        "selections": [
                          (v88/*: any*/),
                          (v54/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SearchBrowseSortAvailableOptions",
                        "kind": "LinkedField",
                        "name": "options",
                        "plural": true,
                        "selections": [
                          (v88/*: any*/),
                          (v89/*: any*/),
                          (v54/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SearchBrowseCategoryTiles",
                    "kind": "LinkedField",
                    "name": "categoryTiles",
                    "plural": true,
                    "selections": [
                      (v93/*: any*/),
                      (v54/*: any*/),
                      (v104/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "rank",
                        "storageKey": null
                      },
                      (v105/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "image",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SearchCorrectionsType",
                    "kind": "LinkedField",
                    "name": "searchCorrections",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "searchType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "originalSearchTerm",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SearchCorrectionSuggestionsValues",
                        "kind": "LinkedField",
                        "name": "suggestions",
                        "plural": true,
                        "selections": [
                          (v98/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "uri",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "forceSearchUri",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "semanticSearchTerm",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "classificationSearchTerm",
                        "storageKey": null
                      },
                      (v106/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "trackedAbTests",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ItemSearchQueryEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      (v93/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ItemSearchResult",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Item",
                            "kind": "LinkedField",
                            "name": "item",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": (v81/*: any*/),
                                "concreteType": "ItemPhoto",
                                "kind": "LinkedField",
                                "name": "photos",
                                "plural": true,
                                "selections": [
                                  (v85/*: any*/),
                                  (v48/*: any*/)
                                ],
                                "storageKey": "photos(limit:1)"
                              },
                              (v48/*: any*/),
                              (v43/*: any*/),
                              (v107/*: any*/),
                              (v50/*: any*/),
                              (v108/*: any*/),
                              (v109/*: any*/),
                              (v110/*: any*/),
                              (v111/*: any*/),
                              (v115/*: any*/),
                              (v45/*: any*/),
                              (v117/*: any*/),
                              (v47/*: any*/),
                              (v46/*: any*/),
                              (v44/*: any*/),
                              (v118/*: any*/),
                              (v119/*: any*/),
                              (v120/*: any*/),
                              (v51/*: any*/),
                              (v52/*: any*/),
                              (v53/*: any*/),
                              (v55/*: any*/),
                              (v56/*: any*/),
                              (v60/*: any*/),
                              (v61/*: any*/),
                              (v62/*: any*/),
                              (v63/*: any*/),
                              (v65/*: any*/),
                              (v121/*: any*/),
                              (v75/*: any*/),
                              (v123/*: any*/),
                              (v124/*: any*/),
                              (v125/*: any*/),
                              (v126/*: any*/),
                              (v127/*: any*/),
                              (v128/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v48/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ItemSearchSponsored",
                    "kind": "LinkedField",
                    "name": "sponsored",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Item",
                        "kind": "LinkedField",
                        "name": "items",
                        "plural": true,
                        "selections": [
                          (v93/*: any*/),
                          (v48/*: any*/),
                          (v43/*: any*/),
                          (v107/*: any*/),
                          (v50/*: any*/),
                          (v108/*: any*/),
                          (v109/*: any*/),
                          (v110/*: any*/),
                          (v111/*: any*/),
                          (v115/*: any*/),
                          (v45/*: any*/),
                          (v117/*: any*/),
                          (v47/*: any*/),
                          (v46/*: any*/),
                          (v44/*: any*/),
                          (v118/*: any*/),
                          (v119/*: any*/),
                          (v120/*: any*/),
                          (v51/*: any*/),
                          (v52/*: any*/),
                          (v53/*: any*/),
                          (v55/*: any*/),
                          (v56/*: any*/),
                          (v60/*: any*/),
                          (v61/*: any*/),
                          (v62/*: any*/),
                          (v63/*: any*/),
                          (v65/*: any*/),
                          (v121/*: any*/),
                          (v75/*: any*/),
                          (v123/*: any*/),
                          (v124/*: any*/),
                          (v125/*: any*/),
                          (v126/*: any*/),
                          (v127/*: any*/),
                          (v128/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalSponsoredAdsForPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "maxAvailable",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ItemSearchSponsoredMetadata",
                        "kind": "LinkedField",
                        "name": "metadata",
                        "plural": true,
                        "selections": [
                          (v104/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "impressionTrackerLink",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "clickTrackerLink",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MetaData",
                    "kind": "LinkedField",
                    "name": "meta",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "prev",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "disableIndexing",
                        "storageKey": null
                      },
                      (v45/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "topQueries",
                        "storageKey": null
                      },
                      (v96/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "canonical",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "next",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LanguageAnnotations",
                        "kind": "LinkedField",
                        "name": "languageAnnotations",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "href",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hrefLang",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "microdata",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "header",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "subHeader",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "attributeDisplayName",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v106/*: any*/),
                  {
                    "alias": "soldItems",
                    "args": (v129/*: any*/),
                    "concreteType": "soldResultsConnection",
                    "kind": "LinkedField",
                    "name": "soldResults",
                    "plural": false,
                    "selections": [
                      (v92/*: any*/)
                    ],
                    "storageKey": "soldResults(first:28)"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Seller",
                    "kind": "LinkedField",
                    "name": "seller",
                    "plural": false,
                    "selections": [
                      (v94/*: any*/),
                      (v48/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      (v116/*: any*/)
                    ],
                    "concreteType": "SearchBrowsePriceTile",
                    "kind": "LinkedField",
                    "name": "priceTiles",
                    "plural": true,
                    "selections": (v90/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RelatedSearch",
                    "kind": "LinkedField",
                    "name": "relatedSearches",
                    "plural": true,
                    "selections": [
                      (v130/*: any*/),
                      (v105/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "count",
                        "value": 6
                      }
                    ],
                    "concreteType": "CreatorDesigns",
                    "kind": "LinkedField",
                    "name": "creatorDesignTiles",
                    "plural": true,
                    "selections": [
                      (v54/*: any*/),
                      (v64/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LinkData",
                        "kind": "LinkedField",
                        "name": "browsePageLinkData",
                        "plural": false,
                        "selections": (v59/*: any*/),
                        "storageKey": null
                      },
                      (v48/*: any*/)
                    ],
                    "storageKey": "creatorDesignTiles(count:6)"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LinkData",
                    "kind": "LinkedField",
                    "name": "breadcrumbs",
                    "plural": true,
                    "selections": [
                      (v74/*: any*/),
                      (v57/*: any*/),
                      (v58/*: any*/),
                      (v93/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "searchRequestId",
                    "storageKey": null
                  },
                  {
                    "alias": "relatedItems",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "first",
                        "value": 12
                      }
                    ],
                    "concreteType": "youMayAlsoLikeConnection",
                    "kind": "LinkedField",
                    "name": "youMayAlsoLike",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "youMayAlsoLikeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ItemSearchResult",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Item",
                                "kind": "LinkedField",
                                "name": "item",
                                "plural": false,
                                "selections": [
                                  (v43/*: any*/),
                                  (v45/*: any*/),
                                  (v51/*: any*/),
                                  (v52/*: any*/),
                                  (v53/*: any*/),
                                  (v55/*: any*/),
                                  (v56/*: any*/),
                                  (v60/*: any*/),
                                  (v120/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ShipmentQuote",
                                    "kind": "LinkedField",
                                    "name": "shipmentQuotes",
                                    "plural": true,
                                    "selections": [
                                      (v112/*: any*/),
                                      (v113/*: any*/),
                                      (v114/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ShipmentServiceMethod",
                                        "kind": "LinkedField",
                                        "name": "serviceMethod",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Region",
                                            "kind": "LinkedField",
                                            "name": "region",
                                            "plural": false,
                                            "selections": [
                                              (v91/*: any*/),
                                              (v48/*: any*/),
                                              (v99/*: any*/)
                                            ],
                                            "storageKey": null
                                          },
                                          (v48/*: any*/)
                                        ],
                                        "storageKey": null
                                      },
                                      (v48/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v61/*: any*/),
                                  (v62/*: any*/),
                                  (v63/*: any*/),
                                  (v65/*: any*/),
                                  (v121/*: any*/),
                                  (v75/*: any*/),
                                  (v123/*: any*/),
                                  (v124/*: any*/),
                                  (v125/*: any*/),
                                  (v50/*: any*/),
                                  (v126/*: any*/),
                                  (v118/*: any*/),
                                  (v108/*: any*/),
                                  (v107/*: any*/),
                                  (v109/*: any*/),
                                  (v110/*: any*/),
                                  (v111/*: any*/),
                                  (v117/*: any*/),
                                  (v47/*: any*/),
                                  (v46/*: any*/),
                                  (v44/*: any*/),
                                  (v119/*: any*/),
                                  (v48/*: any*/),
                                  (v127/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v48/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "youMayAlsoLike(first:12)"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clearAppliedFiltersLinkReference",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SearchBrowseFilter",
                    "kind": "LinkedField",
                    "name": "filters",
                    "plural": true,
                    "selections": [
                      (v98/*: any*/),
                      (v103/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SearchBrowseFilterValue",
                        "kind": "LinkedField",
                        "name": "values",
                        "plural": true,
                        "selections": [
                          (v54/*: any*/),
                          (v88/*: any*/),
                          (v89/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "FilterPropertiesType",
                            "kind": "LinkedField",
                            "name": "properties",
                            "plural": false,
                            "selections": [
                              (v102/*: any*/),
                              (v101/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "min",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "max",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "linkable",
                            "storageKey": null
                          },
                          (v67/*: any*/),
                          (v87/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "stats",
                            "kind": "LinkedField",
                            "name": "stats",
                            "plural": true,
                            "selections": [
                              (v73/*: any*/),
                              (v131/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v100/*: any*/),
                          (v99/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v132/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "originalFilterName",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isAttributePage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayMaxNumberOfPages",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LinkData",
                    "kind": "LinkedField",
                    "name": "popularSearchesLink",
                    "plural": false,
                    "selections": [
                      (v74/*: any*/),
                      (v57/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InternalBuyLinkType",
                    "kind": "LinkedField",
                    "name": "relatedPages",
                    "plural": true,
                    "selections": (v133/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SearchBrowseInteriorPhotosModule",
                    "kind": "LinkedField",
                    "name": "interiorPhotos",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "InteriorPhotosModulePhotoSearchParam",
                        "kind": "LinkedField",
                        "name": "photoSearchParam",
                        "plural": false,
                        "selections": [
                          (v98/*: any*/),
                          (v131/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "photosBrowseLink",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "InteriorPhotosModuleSearchBrowseBackLink",
                        "kind": "LinkedField",
                        "name": "searchBrowseBackLink",
                        "plural": false,
                        "selections": [
                          (v54/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "linkUrl",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v92/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PhotosSearchBrowsePhoto",
                        "kind": "LinkedField",
                        "name": "photos",
                        "plural": true,
                        "selections": [
                          (v48/*: any*/),
                          (v43/*: any*/),
                          (v98/*: any*/),
                          (v57/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PhotoLike",
                            "kind": "LinkedField",
                            "name": "photoLike",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "totalPhotoLikes",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LinkData",
                        "kind": "LinkedField",
                        "name": "relatedPhotoSearchLinks",
                        "plural": true,
                        "selections": [
                          (v57/*: any*/),
                          (v74/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": "editorial",
                    "args": (v134/*: any*/),
                    "concreteType": "WordpressArticlesType",
                    "kind": "LinkedField",
                    "name": "editorialArticles",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WordpressArticleType",
                        "kind": "LinkedField",
                        "name": "articles",
                        "plural": true,
                        "selections": [
                          (v135/*: any*/),
                          (v45/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "WordpressArticleImageType",
                            "kind": "LinkedField",
                            "name": "imageUrl",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "thumb",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "medium",
                                "storageKey": null
                              },
                              (v136/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v97/*: any*/),
                          (v42/*: any*/),
                          (v137/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v92/*: any*/)
                    ],
                    "storageKey": "editorialArticles(first:8)"
                  },
                  {
                    "alias": null,
                    "args": (v134/*: any*/),
                    "concreteType": "WordpressArticlesType",
                    "kind": "LinkedField",
                    "name": "editorialArticles",
                    "plural": false,
                    "selections": [
                      (v92/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WordpressArticleType",
                        "kind": "LinkedField",
                        "name": "articles",
                        "plural": true,
                        "selections": [
                          (v135/*: any*/),
                          (v45/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "WordpressArticleImageType",
                            "kind": "LinkedField",
                            "name": "imageUrl",
                            "plural": false,
                            "selections": [
                              (v136/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v97/*: any*/),
                          (v42/*: any*/),
                          (v137/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "editorialArticles(first:8)"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "relatedCreatorsConnection",
                    "kind": "LinkedField",
                    "name": "relatedCreators",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "relatedCreatorsEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "RelatedCreatorType",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v60/*: any*/),
                              {
                                "alias": "shopAllTitle",
                                "args": null,
                                "kind": "ScalarField",
                                "name": "title",
                                "storageKey": null
                              },
                              {
                                "alias": "creatorName",
                                "args": null,
                                "kind": "ScalarField",
                                "name": "pageObjectName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "items",
                                "plural": true,
                                "selections": [
                                  (v93/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v45/*: any*/),
                                      (v52/*: any*/),
                                      {
                                        "alias": null,
                                        "args": (v81/*: any*/),
                                        "concreteType": "ItemPhoto",
                                        "kind": "LinkedField",
                                        "name": "photos",
                                        "plural": true,
                                        "selections": [
                                          (v84/*: any*/),
                                          (v48/*: any*/)
                                        ],
                                        "storageKey": "photos(limit:1)"
                                      }
                                    ],
                                    "type": "Item",
                                    "abstractKey": null
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v48/*: any*/)
                                    ],
                                    "type": "Node",
                                    "abstractKey": "__isNode"
                                  }
                                ],
                                "storageKey": null
                              },
                              (v48/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "soldResultsConnection",
                    "kind": "LinkedField",
                    "name": "soldResults",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "soldResultsEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ItemSearchResult",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Item",
                                "kind": "LinkedField",
                                "name": "item",
                                "plural": false,
                                "selections": [
                                  (v43/*: any*/),
                                  (v45/*: any*/),
                                  (v49/*: any*/),
                                  (v117/*: any*/),
                                  (v47/*: any*/),
                                  (v46/*: any*/),
                                  (v44/*: any*/),
                                  (v118/*: any*/),
                                  (v119/*: any*/),
                                  (v120/*: any*/),
                                  (v138/*: any*/),
                                  (v51/*: any*/),
                                  (v52/*: any*/),
                                  (v53/*: any*/),
                                  (v55/*: any*/),
                                  (v56/*: any*/),
                                  (v60/*: any*/),
                                  (v61/*: any*/),
                                  (v62/*: any*/),
                                  (v63/*: any*/),
                                  (v65/*: any*/),
                                  (v121/*: any*/),
                                  (v75/*: any*/),
                                  (v123/*: any*/),
                                  (v124/*: any*/),
                                  (v125/*: any*/),
                                  (v50/*: any*/),
                                  (v126/*: any*/),
                                  (v48/*: any*/),
                                  (v127/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v48/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "canFollowSearch",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InternalBuyLinkType",
                    "kind": "LinkedField",
                    "name": "internalBuyLinks",
                    "plural": true,
                    "selections": (v133/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "faqHeader",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "QuestionType",
                    "kind": "LinkedField",
                    "name": "questionsAnswers",
                    "plural": true,
                    "selections": [
                      (v48/*: any*/),
                      (v105/*: any*/),
                      (v45/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "answerCount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AnswerType",
                        "kind": "LinkedField",
                        "name": "answers",
                        "plural": true,
                        "selections": [
                          (v43/*: any*/),
                          (v74/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "formattedCreatedDate",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Seller",
                            "kind": "LinkedField",
                            "name": "author",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "SellerPreferences",
                                "kind": "LinkedField",
                                "name": "sellerPreferences",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "label",
                                    "storageKey": null
                                  },
                                  (v88/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "scrollLogo",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v48/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": "soldItemResults",
                    "args": (v129/*: any*/),
                    "concreteType": "soldResultsConnection",
                    "kind": "LinkedField",
                    "name": "soldResults",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "soldResultsEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ItemSearchResult",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Item",
                                "kind": "LinkedField",
                                "name": "item",
                                "plural": false,
                                "selections": [
                                  (v43/*: any*/),
                                  (v139/*: any*/),
                                  (v45/*: any*/),
                                  (v51/*: any*/),
                                  (v52/*: any*/),
                                  (v53/*: any*/),
                                  (v55/*: any*/),
                                  (v56/*: any*/),
                                  (v60/*: any*/),
                                  (v120/*: any*/),
                                  (v138/*: any*/),
                                  (v61/*: any*/),
                                  (v62/*: any*/),
                                  (v63/*: any*/),
                                  (v65/*: any*/),
                                  {
                                    "alias": null,
                                    "args": [
                                      (v116/*: any*/),
                                      (v66/*: any*/)
                                    ],
                                    "concreteType": "ItemDisplayPriceType",
                                    "kind": "LinkedField",
                                    "name": "displayPrice",
                                    "plural": true,
                                    "selections": (v71/*: any*/),
                                    "storageKey": null
                                  },
                                  (v75/*: any*/),
                                  (v123/*: any*/),
                                  (v86/*: any*/),
                                  (v50/*: any*/),
                                  (v124/*: any*/),
                                  (v125/*: any*/),
                                  (v118/*: any*/),
                                  (v44/*: any*/),
                                  (v46/*: any*/),
                                  (v47/*: any*/),
                                  (v49/*: any*/),
                                  (v48/*: any*/),
                                  (v127/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v48/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "soldResults(first:28)"
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "first",
                        "value": 20
                      }
                    ],
                    "concreteType": "youMayAlsoLikeConnection",
                    "kind": "LinkedField",
                    "name": "youMayAlsoLike",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "youMayAlsoLikeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ItemSearchResult",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Item",
                                "kind": "LinkedField",
                                "name": "item",
                                "plural": false,
                                "selections": [
                                  (v43/*: any*/),
                                  (v50/*: any*/),
                                  (v139/*: any*/),
                                  (v45/*: any*/),
                                  (v51/*: any*/),
                                  (v52/*: any*/),
                                  (v53/*: any*/),
                                  (v55/*: any*/),
                                  (v56/*: any*/),
                                  (v60/*: any*/),
                                  (v120/*: any*/),
                                  (v138/*: any*/),
                                  (v61/*: any*/),
                                  (v62/*: any*/),
                                  (v63/*: any*/),
                                  (v65/*: any*/),
                                  (v121/*: any*/),
                                  (v75/*: any*/),
                                  (v80/*: any*/),
                                  (v86/*: any*/),
                                  {
                                    "alias": null,
                                    "args": [
                                      {
                                        "kind": "Literal",
                                        "name": "size",
                                        "value": "thumb"
                                      }
                                    ],
                                    "kind": "ScalarField",
                                    "name": "firstPhotoWebPath",
                                    "storageKey": "firstPhotoWebPath(size:\"thumb\")"
                                  },
                                  (v44/*: any*/),
                                  (v46/*: any*/),
                                  (v47/*: any*/),
                                  (v49/*: any*/),
                                  (v48/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v48/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "youMayAlsoLike(first:20)"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BottomModule",
                    "kind": "LinkedField",
                    "name": "bottomModule",
                    "plural": true,
                    "selections": [
                      (v45/*: any*/),
                      (v96/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ]
          },
          {
            "condition": "skipUser",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "condition": "fetchUser",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": (v36/*: any*/),
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TradeFirmType",
                        "kind": "LinkedField",
                        "name": "tradeFirm",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TradeFirmRewards",
                            "kind": "LinkedField",
                            "name": "rewards",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ParentProgram",
                                "kind": "LinkedField",
                                "name": "currentProgram",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "LoyaltyProgram",
                                    "kind": "LinkedField",
                                    "name": "programInfo",
                                    "plural": false,
                                    "selections": [
                                      (v99/*: any*/),
                                      (v48/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v48/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserPreferences",
                        "kind": "LinkedField",
                        "name": "preferences",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "measurementUnit",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isVerifiedTrade",
                        "storageKey": null
                      },
                      (v43/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AddressBook",
                        "kind": "LinkedField",
                        "name": "addressBook",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Address",
                            "kind": "LinkedField",
                            "name": "personalAddress",
                            "plural": false,
                            "selections": (v140/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Address",
                            "kind": "LinkedField",
                            "name": "proAddress",
                            "plural": false,
                            "selections": (v140/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Address",
                            "kind": "LinkedField",
                            "name": "addressList",
                            "plural": true,
                            "selections": (v140/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": "showStrategicPartnerFilter",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "name",
                            "value": "STRATEGIC_PARTNER_FILTERING"
                          }
                        ],
                        "kind": "ScalarField",
                        "name": "hasPermission",
                        "storageKey": "hasPermission(name:\"STRATEGIC_PARTNER_FILTERING\")"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "canShowSellerName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "canShowFolder",
                        "storageKey": null
                      },
                      {
                        "condition": "fetchUser",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": (v141/*: any*/),
                            "concreteType": "favoritesConnection",
                            "kind": "LinkedField",
                            "name": "favorites",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "favoritesEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Favorite",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v43/*: any*/),
                                      (v54/*: any*/),
                                      (v48/*: any*/),
                                      {
                                        "kind": "ClientExtension",
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__id",
                                            "storageKey": null
                                          }
                                        ]
                                      },
                                      (v93/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PageInfo",
                                "kind": "LinkedField",
                                "name": "pageInfo",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": (v141/*: any*/),
                            "filters": [],
                            "handle": "connection",
                            "key": "SharedSaveSearchContext_favorites",
                            "kind": "LinkedHandle",
                            "name": "favorites"
                          }
                        ]
                      },
                      (v93/*: any*/),
                      (v48/*: any*/)
                    ],
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "condition": "hasPersonalizedFilter",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": (v40/*: any*/),
                "concreteType": "ShippingFilterType",
                "kind": "LinkedField",
                "name": "shippingFilters",
                "plural": true,
                "selections": [
                  (v132/*: any*/)
                ],
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-finding/505.0.0-2025-04-08T14:45:28.242Z/SbMobileLayoutRefetchQuery",
    "metadata": {},
    "name": "SbMobileLayoutRefetchQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "c6069f90fcc1e88e02e537e1a462fb62";

export default node;
