import { type FC } from 'react';
import { graphql, useFragment } from 'react-relay';
import { FormattedMessage } from 'dibs-react-intl';

import { SaveSearchButton } from '../../components/global/SaveSearchButton/SaveSearchButton';
import { getFilterValue } from '../SbSharedRefineMenu/sbSharedRefineMenuHelpers';
import { trackFollowButtonClick } from '../../utils/tracking/searchBrowse/trackFollowSearch';
import { authLabels, locations } from '../../utils/tracking/shared/followSearchTracking';

import { pageTypeConstants as pageTypes } from '../../constants/pageTypeConstants';

import styles from './SbMobileFollowPage.scss';

import { type SbMobileFollowPage_itemSearch$key } from './__generated__/SbMobileFollowPage_itemSearch.graphql';

const { SEARCH } = pageTypes;

type Props = {
    itemSearch: SbMobileFollowPage_itemSearch$key;
};

const itemSearchFragment = graphql`
    fragment SbMobileFollowPage_itemSearch on ItemSearchQueryConnection {
        appliedFilters {
            name
            values {
                urlLabel
            }
        }
        ...SaveSearchButton_itemSearch
    }
`;

export const SbMobileFollowPage: FC<Props> = ({ itemSearch: itemSearchRef }) => {
    const itemSearch = useFragment(itemSearchFragment, itemSearchRef);

    const { appliedFilters } = itemSearch;

    const searchTerm = getFilterValue(appliedFilters, SEARCH)?.urlLabel;

    return (
        <div className={styles.container}>
            <div className={styles.messageWrapper}>
                <FormattedMessage
                    id="sb.SbMobileFollowPage.follow"
                    defaultMessage={
                        'Save "<b>{searchTerm}</b>", and we\'ll notify you when there are new listings for this search.'
                    }
                    values={{ searchTerm, b: content => <b>{content}</b> }}
                />
            </div>
            <SaveSearchButton
                itemSearch={itemSearch}
                locationLabel={authLabels[locations.SEARCH_BROWSE]}
                onComplete={isFollowing => {
                    trackFollowButtonClick({
                        isFollowing,
                        label: 'top of results',
                        noninteraction: 0,
                    });
                }}
                type="heart"
            />
        </div>
    );
};
